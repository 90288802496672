import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const CatalogSearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <div className="relative w-full max-w-xl mb-4">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search features..."
        className="w-full h-10 font-inter font-lighter text-sm px-10 py-2 border border-gray-100 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-morange-200"
      />
      <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
    </div>
  );
};

export { CatalogSearchBar };
