// Adds layers to the Mapbox map instance to create a choropleth map
import { formatTitle } from "../formatText";
import { formatLargeNumber } from "../numberFormats";

// Define the partisan color scale once
const PARTISAN_COLOR_SCALE = [
  [0, "#780000"], // Deep red
  [25, "#FF6767"], // Light red
  [50, "#fdf0d5"], // Neutral
  [75, "#669bbc"], // Light blue
  [100, "#003049"], // Deep blue
];

const getPartisanColor = (value) => {
  for (let i = 0; i < PARTISAN_COLOR_SCALE.length - 1; i++) {
    if (value <= PARTISAN_COLOR_SCALE[i + 1][0]) {
      return PARTISAN_COLOR_SCALE[i][1];
    }
  }
  return PARTISAN_COLOR_SCALE[PARTISAN_COLOR_SCALE.length - 1][1];
};

function defaultGradient(min, max) {
  const colors = ["#E07A5F", "#EAB69F", "#CCD4C5", "#85A9A6", "#15616D"];

  const steps = colors.length;
  return colors.map((color, i) => [
    min + (max - min) * (i / (steps - 1)),
    color,
  ]);
}

const generateColorScale = (features, metric) => {
  // Get all valid values for this metric
  const values = features
    .map((f) => f.properties[metric])
    .filter((v) => v != null);
  const min = Math.min(...values);
  const max = Math.max(...values);

  if (values.length === 0) return [];

  // Special cases for specific metrics
  if (metric === "avg_Partisan Score") {
    return PARTISAN_COLOR_SCALE;
  }
  // If we only have one unique value, return a single color scale
  const uniqueValues = [...new Set(values)];

  if (uniqueValues.length === 1) {
    return [[uniqueValues[0], "#CCD4C5"]]; // Default color for other metrics
  }

  // For percentage metrics
  else if (values.every((v) => v <= 1)) {
    if (max - min < 0.25) {
      // Pad both sides of the gradient so the gap is .4
      const diff = 0.25 - (max - min);
      return defaultGradient(
        Math.max(min - diff / 2, 0),
        Math.min(max + diff / 2, 1)
      );
    }
    if (max - min > 0.75) {
      // If the range is greater than 75, use a linear gradient
      return defaultGradient(0, 1);
    }
    return defaultGradient(min, max);
  }

  // For score metrics
  else if (values.every((v) => v <= 100)) {
    if (max - min < 25) {
      // Pad both sides of the gradient so the gap is 40
      const diff = 25 - (max - min);
      return defaultGradient(
        Math.max(min - diff / 2, 0),
        Math.min(max + diff / 2, 100)
      );
    }
    if (max - min > 75) {
      // If the range is greater than 75, use a linear gradient
      return defaultGradient(0, 100);
    }
    return defaultGradient(min, max);
  }

  // Add helper function to detect if data is heavily skewed
  const isHighlySkewed = (values) => {
    const sortedValues = [...values].sort((a, b) => a - b);
    const median = sortedValues[Math.floor(sortedValues.length * 0.5)];
    const max = sortedValues[sortedValues.length - 1];

    // Ratio between max and median
    // Should be ~2 if it's a linear scale
    const maxMedianRatio = max / median;

    return maxMedianRatio > 10;
  };

  const colors = defaultGradient(min, max);

  // For numeric metrics, check if highly skewed
  const steps = colors.length;
  if (isHighlySkewed(values)) {
    const logMin = Math.log(min || 1); // Avoid log(0)
    const logMax = Math.log(max);
    const logStep = (logMax - logMin) / (steps - 1);

    return Array.from({ length: steps }, (_, i) => {
      const logValue = logMin + logStep * i;
      const value = Math.exp(logValue);
      return [value, colors[i][1]];
    });
  }

  return colors;
};

export const addMapLayer = (map, geojson, metric) => {
  geojson.features.forEach((feature) => {
    feature.id = feature.properties.geoid;
  });

  const colorScale = generateColorScale(geojson.features, metric);

  // If no valid color scale could be generated, use a default
  if (colorScale.length === 0) {
    console.warn(`No valid data range for metric: ${metric}`);
    return;
  }

  // For single-value datasets, use a simple fill color
  const paintProperty =
    colorScale.length === 1
      ? {
          "fill-color": [
            "case",
            ["!=", ["get", metric], null],
            colorScale[0][1], // Use the single color
            "rgba(0, 0, 0, 0.0)", // Transparent if no value
          ],
          "fill-opacity": ["case", ["!=", ["get", metric], null], 0.7, 0],
        }
      : {
          "fill-color": [
            "case",
            ["!=", ["get", metric], null],
            ["interpolate", ["linear"], ["get", metric], ...colorScale.flat()],
            "rgba(0, 0, 0, 0.0)", // Transparent if no value
          ],
          "fill-opacity": ["case", ["!=", ["get", metric], null], 0.7, 0],
        };

  // Add a layer with polygons colored based on the 'value' property
  map.addLayer({
    id: "polygons-fill",
    type: "fill",
    source: "polygons",
    paint: paintProperty,
  });

  // Add outline layer
  map.addLayer({
    id: "polygons-outline",
    type: "line",
    source: "polygons",
    layout: {},
    paint: {
      "line-color": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        "#FF5A1F", // orange color for selected state
        ["boolean", ["feature-state", "hover"], false],
        "#666666",
        "#808080",
      ],
      "line-width": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        3,
        ["boolean", ["feature-state", "hover"], false],
        1,
        0.5,
      ],
    },
  });

  // Add legend
  addLegend(map, colorScale, metric);
};

const addLegend = (map, colorScale, metric) => {
  // Remove existing legend if it exists
  const existingLegend = document.getElementById("map-legend");
  if (existingLegend) {
    existingLegend.remove();
  }

  const legend = document.createElement("div");
  legend.id = "map-legend";
  legend.className = "absolute bottom-8 right-8 bg-white p-4 rounded shadow-lg";

  const title = document.createElement("h4");
  title.className = "text-sm font-semibold mb-2";
  title.textContent = formatTitle(metric);
  legend.appendChild(title);

  colorScale.forEach(([value, color]) => {
    const item = document.createElement("div");
    item.className = "flex items-center gap-2 text-xs";

    const colorBox = document.createElement("div");
    colorBox.className = "w-4 h-4";
    colorBox.style.backgroundColor = color;

    const label = document.createElement("span");
    label.textContent =
      Math.max(...colorScale.map(([value]) => value)) <= 1
        ? `${Math.round(value * 100)}%`
        : formatLargeNumber(value);

    item.appendChild(colorBox);
    item.appendChild(label);
    legend.appendChild(item);
  });

  map.getContainer().appendChild(legend);
};
