import { useState, useEffect } from "react";
import apiClient from "./axios";

export const useFetchFeatures = (endpoint) => {
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await apiClient.get(endpoint);
        const data =
          response.data[endpoint.includes("metrics") ? "metrics" : "filters"];
        // Filter out features with "topline" category
        const filteredData = data.filter(
          (feature) => feature.category !== "topline"
        );
        setFeatures(filteredData);
        setIsLoading(false);
      } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error);
        setIsLoading(false);
      }
    };

    fetchFeatures();
  }, [endpoint]);

  const categorizedFeatures = features.reduce((acc, feature) => {
    if (!acc[feature.category]) {
      acc[feature.category] = [];
    }
    acc[feature.category].push(feature);
    return acc;
  }, {});

  return { features, categorizedFeatures, isLoading };
};
