import { Card } from "../../components/layouts/Cards";
import WindChartCard from "../../components/cards/WindChartCard";
import { colorCodeText } from "../../utility/formatText";
import { TrueViewsToplines } from "../../components/data/TrueViewsToplines";
import TrueViewsCard from "../../components/cards/TrueViewsCard";
import { useState, useEffect } from "react";
import apiClient from "../../utility/axios";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const defaultQuestions = {
  healthcare: {
    code: "cces2012_repeal.aca",
    label: "Health Care",
    topic: "health_care",
    question:
      "Would you support or oppose repealing the entire Affordable Care Act?",
  },
  abortion: {
    code: "cces2014_abortionlateterm",
    label: "Abortion",
    topic: "abortion",
    question:
      "Do you support or oppose allowing abortions in cases of rape or incest?",
  },
  climate: {
    code: "cces2014_staterps",
    label: "Climate",
    topic: "climate",
    question:
      "Do you support or oppose state requirements for a minimum amount of renewable fuels?",
  },
  gunControl: {
    code: "cces2013_gunsbackgroundchecks",
    label: "Gun Control",
    topic: "gun_control",
    question:
      "Would you support or oppose requiring background checks for all gun purchases?",
  },
  police: {
    code: "cces2016_increasepolice",
    label: "Police and Criminal Justice",
    topic: "police_and_criminal_justice",
    question:
      "Do you support or oppose increasing the number of police officers?",
  },
  immigration: {
    code: "cces2020_imm.pathwaycitizen",
    label: "Immigration",
    topic: "immigration",
    question:
      "Do you support or oppose providing a pathway to citizenship for undocumented immigrants?",
  },
  minimumWage: {
    code: "minimumwage_15dollars",
    label: "Minimum Wage",
    topic: "minimum_wage",
    question:
      "Do you support or oppose raising the minimum wage to $15 per hour?",
  },
};

const TrueViewsSection = ({ queryString, comparisonQueryString }) => {
  const [questions, setQuestions] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedTopics, setExpandedTopics] = useState({
    healthcare: true,
    abortion: false,
    climate: false,
    gunControl: false,
    police: false,
    immigration: false,
    minimumWage: false,
  });

  useEffect(() => {
    const fetchAllQuestions = async () => {
      try {
        setLoading(true);
        const questionsByTopic = {};

        // Fetch questions for each topic
        for (const [topic, defaultQ] of Object.entries(defaultQuestions)) {
          const response = await apiClient.get(
            `/api/trueviews/metadata?${queryString}&topic=${defaultQ.topic}&year=2024`
          );
          questionsByTopic[topic] = response.data || [];
        }

        setQuestions(questionsByTopic);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllQuestions();
  }, [queryString]);

  const toggleTopic = (topic) => {
    setExpandedTopics((prev) => ({
      ...prev,
      [topic]: !prev[topic],
    }));
  };

  return (
    <>
      <Card
        title={<p className="!text-3xl font-bold text-left">TrueViews</p>}
        id="trueviews"
        colspan={12}
        children={
          <>
            <div className="border-b border border-slate-900 w-full col-span-12" />
          </>
        }
        className="pt-4"
        footnote={
          <div className="mt-4">
            Source: Harvard University's TrueViews estimates based on survey
            data and demographic modeling.
          </div>
        }
      />

      {loading ? (
        <Card colspan={12} className="mt-4">
          <div className="flex justify-center py-8">
            <l-dot-stream size="40" speed="2.5" color="black"></l-dot-stream>
          </div>
        </Card>
      ) : (
        Object.entries(questions).map(([topic, topicQuestions]) => (
          <div key={topic} className="mt-2">
            <button
              onClick={() => toggleTopic(topic)}
              className={`flex items-center gap-2 cursor-pointer p-3 rounded-md w-full transition-colors hover:bg-morange-100`}
            >
              <ChevronDownIcon
                className={`h-6 w-6 transition-transform ${
                  expandedTopics[topic] ? "rotate-180" : ""
                }`}
              />
              <h2 className="text-2xl font-bold tracking-tighter">
                {defaultQuestions[topic].label}
              </h2>
            </button>

            {expandedTopics[topic] && (
              <div className="mt-1 space-y-4">
                {topicQuestions.map((question) => (
                  <Card key={question.question_code} colspan={12} className="">
                    <div className="flex gap-4">
                      <div className="w-2/3">
                        <div className="text-2xl font-light">
                          {question.question
                            .split(/(support|oppose|agree|disagree)/gi)
                            .map((part, i) =>
                              part.match(/support|oppose|agree|disagree/gi) ? (
                                <span key={i} className="italic">
                                  {part}
                                </span>
                              ) : (
                                part
                              )
                            )}
                        </div>
                      </div>
                      <div className="w-1/3">
                        <TrueViewsToplines
                          endpoint={`trueviews/estimates?${queryString}&question_code=${question.question_code}`}
                          comparisonEndpoint={
                            comparisonQueryString
                              ? `trueviews/estimates?${comparisonQueryString}&question_code=${question.question_code}`
                              : null
                          }
                          question={question.question}
                        />
                      </div>
                    </div>
                    <TrueViewsCard
                      id={`trueviews-${question.question_code}-chart`}
                      endpoint={`trueviews/estimates?${queryString}&question_code=${question.question_code}`}
                      comparisonEndpoint={
                        comparisonQueryString
                          ? `trueviews/estimates?${comparisonQueryString}&question_code=${question.question_code}`
                          : undefined
                      }
                      endYear="2024"
                      colspan={12}
                      height={300}
                      title={null}
                    />
                  </Card>
                ))}
              </div>
            )}
          </div>
        ))
      )}
    </>
  );
};

export { TrueViewsSection };
