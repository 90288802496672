import React, { useState, useEffect } from "react";
import FuzzyGeoSearch from "../../search/FuzzyGeoSearch";
import { ReusableCombobox } from "../../forms/ReusableCombobox";
import { Button } from "../../forms/Button";
import apiClient from "../../../utility/axios";

const PlacesTab = ({ onSearch }) => {
  const [selectedGeography, setSelectedGeography] = useState(null);
  const [selectedGeographyLevel, setSelectedGeographyLevel] = useState(null);

  const [geographyOptions, setGeographyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGeographyLevels = async () => {
      try {
        const response = await apiClient.get("/api/config/geography-levels");
        const data = response.data.geography_levels;

        // Filter out 'country' and convert to array of values
        const { country, ...filteredLevels } = data;
        const options = Object.values(filteredLevels);

        setGeographyOptions(options);
      } catch (error) {
        console.error("Error fetching geography levels:", error);
        setGeographyOptions([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGeographyLevels();
  }, []);

  const handleGeoSelect = (geo) => {
    setSelectedGeography(geo);
  };

  const handleGeoLevelSelect = (level) => {
    setSelectedGeographyLevel(level);
  };

  const handleAddPair = () => {
    if (selectedGeography) {
      const pair = selectedGeographyLevel
        ? `${selectedGeographyLevel} in ${selectedGeography.name}`
        : selectedGeography.name;

      const geographyObject = {
        name: selectedGeography.name,
        level: selectedGeographyLevel || null,
        geo_query: selectedGeography.geo_query,
        geography_pair: pair,
      };
      onSearch(geographyObject);
    }
  };

  return (
    <div className="relative">
      <div className="flex items-center space-x-4">
        <FuzzyGeoSearch handleSelect={handleGeoSelect} className="h-10" />
        <ReusableCombobox
          items={geographyOptions}
          onSelect={(item) => handleGeoLevelSelect(item || null)}
          displayValue={(item) => (item ? item.replace(/_/g, " ") : "")}
          formatOption={(item) => item.replace(/_/g, " ")}
          className="!mt-0 h-10"
          placeholder="Select geography level (optional)"
          isLoading={isLoading}
        />
        <Button
          onClick={handleAddPair}
          className="font-medium text-slate-700 bg-morange-100 hover:bg-morange-200 hover:ring-2 hover:ring-morange-200 outline outline-2 outline-morange-200 !mt-0 !mb-0"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export { PlacesTab };
