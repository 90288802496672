import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDispatch, useSelector } from "react-redux";
import { MetricsTab } from "./tabs/MetricsTab";
import { FiltersTab } from "./tabs/FiltersTab";
import { PlacesTab } from "./tabs/PlacesTab";
import { tabColors } from "../../pages/search/config";
import { Badge } from "../text/Badge";
import { Button } from "../forms/Button";
import { useSelectionManager } from "../../hooks/useSelectionManager";
import { FeatureTypeConfig } from "./config/featureTypes";

const DataCatalogModal = ({
  isOpen,
  onClose,
  onApplySelection,
  firstActiveTab = "metrics",
}) => {
  const dispatch = useDispatch();
  const { parameters } = useSelector((state) => state.search);
  const [activeTab, setActiveTab] = useState(firstActiveTab);
  const [tabColor, setTabColor] = useState(tabColors[firstActiveTab]);
  const tabRefs = useRef([]);
  const tabs = ["metrics", "filters", "places"];
  const { selections, addSelection, removeSelection, isSelected } =
    useSelectionManager(parameters);

  const [underlineProps, setUnderline] = useSpring(() => ({
    left: 0,
    width: 0,
  }));

  useEffect(() => {
    setTabColor(tabColors[activeTab]);

    const activeTabIndex = tabs.indexOf(activeTab);
    if (activeTabIndex !== -1 && tabRefs.current[activeTabIndex]) {
      const tabElement = tabRefs.current[activeTabIndex];
      setUnderline({
        left: tabElement.offsetLeft,
        width: tabElement.offsetWidth,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(firstActiveTab);
  }, [firstActiveTab]);

  const handleItemSelect = (item, type) => {
    if (isSelected(item, type)) {
      removeSelection(item, type);
    } else {
      addSelection(item, type);
    }
  };

  const handleApplySelection = () => {
    if (Object.keys(selections).length === 0) return;
    // Add default topline metric if not present
    const defaultTopline = {
      type: "categorical",
      metric: "topline",
      display: "Registered Voters",
      aggregation: "count",
      value: ["all"],
      display_values: ["All"],
    };

    const hasTopline = selections.metrics?.metrics?.some(
      (m) => m.metric === "topline" && m.aggregation === "count"
    );

    if (!hasTopline) {
      selections.metrics = {
        metrics: [defaultTopline, ...(selections.metrics?.metrics || [])],
      };
    }

    // Default to United States if no places are selected
    if (selections.places.length === 0) {
      selections.places = [
        {
          name: "United States",
          geo_query: "",
        },
      ];
    }

    console.log("Applying selections:", selections);
    onApplySelection(selections);
    onClose();
  };

  return (
    <div
      className={`fixed inset-0 z-50 ${isOpen ? "flex" : "hidden"} items-start justify-center bg-black bg-opacity-50`}
      style={{ paddingTop: "10vh" }}
    >
      <div className="bg-style-bg rounded-lg shadow-lg max-w-6xl w-full min-h-[400px] max-h-[85vh] flex">
        {/* Main Content Area */}
        <div className="flex-1 overflow-y-auto border-r border-gray-200">
          <div className="sticky top-0 z-10 bg-style-bg">
            {/* Header */}
            <div className="flex justify-between items-center p-6 border-b border-gray-200">
              <h2 className="text-2xl font-semibold">Data Catalog</h2>
              <button
                className="text-gray-400 hover:text-gray-600"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Tabs and Search Title Row */}
            <div className="flex border-b border-gray-200">
              {/* Tabs Navigation */}
              <div className="flex-1 relative">
                <nav className="flex space-x-4 px-6">
                  {tabs.map((tab, index) => (
                    <button
                      key={tab}
                      ref={(el) => (tabRefs.current[index] = el)}
                      className={`py-2 px-4 ${activeTab === tab ? `text-${tabColor} border-b-2 border-${tabColor}` : `text-gray-600 hover:text-${tabColors[tab]}`} font-medium`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </nav>
                <animated.div
                  className={`absolute bottom-0 h-0.5 bg-${tabColor}`}
                  style={{
                    left: underlineProps.left,
                    width: underlineProps.width,
                  }}
                />
              </div>
            </div>
          </div>

          {/* Tab Content */}
          <div className="p-6">
            <div className="py-2 relative h-auto">
              {activeTab === "metrics" && (
                <MetricsTab
                  activeTab={activeTab}
                  onMetricSelect={(item) => handleItemSelect(item, "metrics")}
                  isItemSelected={isSelected}
                />
              )}
              {activeTab === "filters" && (
                <FiltersTab
                  activeTab={activeTab}
                  onFilterSelect={(item) => handleItemSelect(item, "filters")}
                  isItemSelected={isSelected}
                />
              )}
              {activeTab === "places" && (
                <PlacesTab
                  onSearch={(item) => handleItemSelect(item, "places")}
                />
              )}
            </div>
          </div>
        </div>

        {/* Selected Items Sidebar */}
        <div className="w-80 bg-style-bg flex flex-col">
          {/* Header for sidebar */}
          <div className="sticky top-0 z-10 bg-style-bg border-b border-gray-200">
            <h3 className="p-6 text-lg font-semibold text-gray-700">
              You're searching for:
            </h3>
          </div>

          {/* Scrollable content area */}
          <div className="flex-1 overflow-y-auto p-4">
            {Object.keys(selections).map(
              (type) =>
                selections[type] &&
                ((type === "metrics" && selections[type].metrics.length > 0) ||
                  (type === "filters" && selections[type].filters.length > 0) ||
                  (type === "places" && selections[type].length > 0)) &&
                type !== "search" && (
                  <div key={type} className="mb-6">
                    <div className="font-medium mb-2 text-gray-700">
                      {type.charAt(0).toUpperCase() + type.slice(1)}:
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {type === "metrics" &&
                        selections[type].metrics
                          .map((item) => {
                            const config = FeatureTypeConfig[item.type];
                            if (!config) return null;

                            const badges = config.renderBadge(
                              item,
                              (itemToRemove) =>
                                removeSelection(itemToRemove, type)
                            );
                            return Array.isArray(badges) ? badges : [badges];
                          })
                          .flat()
                          .map((badge) => (
                            <Badge
                              key={badge.key}
                              className={
                                tabColors[type]
                                  ? `!text-${tabColors[type]} !ring-${tabColors[type]} flex items-center shadow-sm`
                                  : "flex items-center shadow-sm"
                              }
                              onClick={badge.onClick}
                            >
                              {badge.label}
                              {badge.label !== "Registered Voters" && (
                                <span className="ml-2 text-black cursor-pointer">
                                  x
                                </span>
                              )}
                            </Badge>
                          ))}
                      {type === "filters" &&
                        selections[type].filters
                          .map((item) => {
                            const config = FeatureTypeConfig.filter;
                            const badges = config.renderBadge(
                              item,
                              (itemToRemove) =>
                                removeSelection(itemToRemove, type)
                            );
                            return badges.map((badge) => (
                              <Badge
                                key={badge.key}
                                className={
                                  tabColors[type]
                                    ? `!text-${tabColors[type]} !ring-${tabColors[type]} flex items-center shadow-sm`
                                    : "flex items-center shadow-sm"
                                }
                                onClick={badge.onClick}
                              >
                                {badge.label}
                                <span className="ml-2 text-black cursor-pointer">
                                  x
                                </span>
                              </Badge>
                            ));
                          })
                          .flat()}
                      {type === "places" &&
                        selections[type].map((item) => {
                          const config = FeatureTypeConfig.places;
                          const badge = config.renderBadge(
                            item,
                            (itemToRemove) =>
                              removeSelection(itemToRemove, type)
                          );
                          return (
                            <Badge
                              key={badge.key}
                              className={
                                tabColors[type]
                                  ? `!text-${tabColors[type]} !ring-${tabColors[type]} flex items-center shadow-sm`
                                  : "flex items-center shadow-sm"
                              }
                              onClick={badge.onClick}
                            >
                              {badge.label}
                              <span className="ml-2 text-black cursor-pointer">
                                x
                              </span>
                            </Badge>
                          );
                        })}
                    </div>
                  </div>
                )
            )}
          </div>

          {/* Fixed button area */}
          <div className="p-4 border-t border-gray-200">
            <div className="flex justify-end">
              <Button
                className="font-medium text-slate-700 bg-morange-100 hover:bg-morange-200 hover:ring-2 hover:ring-morange-200 outline outline-2 outline-morange-200 !mt-0 !mb-0"
                onClick={handleApplySelection}
              >
                Apply Selection
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DataCatalogModal };
