// frontend/src/config/featureTypes.js
import { formatTitle } from "../../../utility/formatText";

export const FeatureTypeConfig = {
  categorical: {
    createMetricItem: (feature, aggregation, selectedValue) => ({
      type: feature.type,
      metric: feature.name,
      display: feature.display,
      ...(aggregation && { aggregation }),
      ...(selectedValue && {
        value: [selectedValue.key],
        display_values: [selectedValue.value],
      }),
    }),
    generateQueryString: (metric) => {
      if (metric.value[0] === "all") {
        return "registered voters";
      }
      return `${metric.aggregation} ${metric.display} ${metric.display_values.join(" ")}`;
    },
    handleMetricSelection: (existingMetrics, newMetric) => {
      const existingIndex = existingMetrics.findIndex(
        (m) =>
          m.metric === newMetric.metric &&
          m.aggregation === newMetric.aggregation
      );

      if (existingIndex >= 0) {
        const updatedMetrics = [...existingMetrics];
        updatedMetrics[existingIndex] = {
          ...updatedMetrics[existingIndex],
          value: Array.from(
            new Set([
              ...updatedMetrics[existingIndex].value,
              ...newMetric.value,
            ])
          ),
          display_values: Array.from(
            new Set([
              ...updatedMetrics[existingIndex].display_values,
              ...newMetric.display_values,
            ])
          ),
        };
        return updatedMetrics;
      }
      return [...existingMetrics, newMetric];
    },
    handleMetricRemoval: (existingMetrics, metricToRemove) => {
      return existingMetrics
        .map((m) => {
          if (
            m.metric !== metricToRemove.metric ||
            m.aggregation !== metricToRemove.aggregation
          ) {
            return m;
          }
          return {
            ...m,
            value: m.value.filter((v) => !metricToRemove.value.includes(v)),
            display_values: m.display_values.filter(
              (_, i) => !metricToRemove.value.includes(m.value[i])
            ),
          };
        })
        .filter((m) => !m.value || m.value.length > 0);
    },
    isSelected: (metrics, item) => {
      return metrics?.metrics.some(
        (m) =>
          m.metric === item.metric &&
          m.aggregation === item.aggregation &&
          item.value?.some((v) => m.value?.includes(v))
      );
    },
    renderBadge: (item, onRemove) => {
      if (!item.value) {
        return {
          key: `${item.metric}_${item.aggregation}`,
          label: `${formatTitle(item.aggregation)} ${item.display}`,
          onClick: () => onRemove(item),
        };
      }

      if (item.metric === "topline") {
        return {
          key: `${item.metric}_all`,
          label: item.display,
          onClick: () => !item.metric?.includes("topline") && onRemove(item),
        };
      }

      return item.value.map((value, idx) => ({
        key: `${item.metric}_${value}_${item.aggregation}`,
        label: `${formatTitle(item.aggregation)} ${item.display} ${item.display_values[idx]}`,
        onClick: () => onRemove({ ...item, value: [value] }),
      }));
    },
  },
  continuous: {
    createMetricItem: (feature, aggregation) => ({
      type: feature.type,
      metric: feature.name,
      display: feature.display,
      aggregation,
    }),
    generateQueryString: (metric) => `${metric.aggregation} ${metric.display}`,
    handleMetricSelection: (existingMetrics, newMetric) => {
      const existingIndex = existingMetrics.findIndex(
        (m) =>
          m.metric === newMetric.metric &&
          m.aggregation === newMetric.aggregation
      );

      if (existingIndex >= 0) {
        const updatedMetrics = [...existingMetrics];
        updatedMetrics[existingIndex] = newMetric;
        return updatedMetrics;
      }
      return [...existingMetrics, newMetric];
    },
    handleMetricRemoval: (existingMetrics, metricToRemove) => {
      return existingMetrics.filter(
        (m) =>
          !(
            m.metric === metricToRemove.metric &&
            m.aggregation === metricToRemove.aggregation
          )
      );
    },
    isSelected: (metrics, item) => {
      return metrics?.metrics.some(
        (m) => m.metric === item.metric && m.aggregation === item.aggregation
      );
    },
    renderBadge: (item, onRemove) => ({
      key: `${item.metric}_${item.aggregation}`,
      label: `${formatTitle(item.aggregation)} ${item.display}`,
      onClick: () => onRemove(item),
    }),
  },
  estimate: {
    createMetricItem: (feature, year) => ({
      type: feature.type,
      metric: feature.name,
      display: feature.display,
      year,
    }),
    generateQueryString: (metric) => `${metric.display} ${metric.year}`,
    handleMetricSelection: (existingMetrics, newMetric) => {
      const existingIndex = existingMetrics.findIndex(
        (m) => m.metric === newMetric.metric && m.year === newMetric.year
      );

      if (existingIndex >= 0) {
        const updatedMetrics = [...existingMetrics];
        updatedMetrics[existingIndex] = newMetric;
        return updatedMetrics;
      }
      return [...existingMetrics, newMetric];
    },
    handleMetricRemoval: (existingMetrics, metricToRemove) => {
      return existingMetrics.filter(
        (m) =>
          !(
            m.metric === metricToRemove.metric && m.year === metricToRemove.year
          )
      );
    },
    isSelected: (metrics, item) => {
      return metrics?.metrics.some(
        (m) => m.metric === item.metric && m.year === item.year
      );
    },
    renderBadge: (item, onRemove) => ({
      key: `${item.metric}_${item.year}`,
      label: `${item.display} ${item.year}`,
      onClick: () => onRemove(item),
    }),
  },
  filter: {
    createFilterItem: (feature, value, displayValue) => ({
      key: feature.name,
      display: feature.display,
      values: [value],
      display_values: [displayValue],
    }),
    generateQueryString: (filter) =>
      `${filter.key} ${filter.display_values.join(" ")}`,
    handleFilterSelection: (existingFilters, newFilter) => {
      const existingIndex = existingFilters.findIndex(
        (f) => f.key === newFilter.key
      );

      if (existingIndex >= 0) {
        const updatedFilters = [...existingFilters];
        updatedFilters[existingIndex] = {
          ...updatedFilters[existingIndex],
          values: [
            ...updatedFilters[existingIndex].values,
            ...newFilter.values,
          ],
          display_values: [
            ...updatedFilters[existingIndex].display_values,
            ...newFilter.display_values,
          ],
        };
        return updatedFilters;
      }
      return [...existingFilters, newFilter];
    },
    handleFilterRemoval: (existingFilters, filterToRemove) => {
      return existingFilters
        .map((f) => {
          if (f.key !== filterToRemove.key) return f;
          return {
            ...f,
            values: f.values.filter((v) => !filterToRemove.values.includes(v)),
            display_values: f.display_values.filter(
              (_, i) => !filterToRemove.values.includes(f.values[i])
            ),
          };
        })
        .filter((f) => f.values.length > 0);
    },
    isSelected: (filters, item) => {
      return filters?.filters.some(
        (f) =>
          f.key === item.key && item.values.some((v) => f.values.includes(v))
      );
    },
    renderBadge: (item, onRemove) =>
      item.values.map((value, idx) => ({
        key: `${item.key}_${value}`,
        label: `${item.display} ${item.display_values[idx]}`,
        onClick: () => onRemove({ ...item, values: [value] }),
      })),
  },
  places: {
    generateQueryString: (place) => place.geography_pair || place.name,
    handlePlaceSelection: (existingPlaces, newPlace) => {
      // Check if place already exists
      const exists = existingPlaces.some(
        (p) => p.name === newPlace.name && p.level === newPlace.level
      );
      if (exists) return existingPlaces;
      return [...existingPlaces, newPlace];
    },
    handlePlaceRemoval: (existingPlaces, placeToRemove) => {
      return existingPlaces.filter(
        (p) =>
          !(p.name === placeToRemove.name && p.level === placeToRemove.level)
      );
    },
    isSelected: (places, item) => {
      return places?.some(
        (p) => p.name === item.name && p.level === item.level
      );
    },
    renderBadge: (item, onRemove) => ({
      key: `${item.name}_${item.level}`,
      label: item.geography_pair || item.name,
      onClick: () => onRemove(item),
    }),
  },
};
