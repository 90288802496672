import { useMemo } from "react";

export const useColumnTypes = (data) => {
  const columnTypes = useMemo(() => {
    if (!data || data.length === 0) return {};

    const types = {};
    const headers = Object.keys(data[0]);

    headers.forEach((header) => {
      const values = data
        .map((row) => row[header])
        .filter((val) => val != null);
      if (values.length === 0) {
        types[header] = "text";
      } else if (values.every((val) => typeof val === "number")) {
        types[header] = values.every((val) => val >= 0 && val <= 1)
          ? "percentage"
          : "numeric";
      } else {
        types[header] = "text";
      }
    });
    return types;
  }, [data]);

  return { columnTypes };
};
