import axios from 'axios';
import oktaAuth from '../auth/oktaAuth';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

apiClient.interceptors.request.use(async (config) => {
  try {
    // Get tokens
    const accessToken = await oktaAuth.getAccessToken();

    // Get user info from Okta
    const userInfo = await oktaAuth.getUser();

    // Add user info to headers
    config.headers.userInfo = {
      email: userInfo.email || 'anonymous'
    };

    // Only send analytics data if email is not from murmuration.org
    if (!userInfo.email?.endsWith('@murmuration.org')) {
      // Set analytics header or property
      config.headers.sendAnalytics = true;
    } else {
      config.headers.sendAnalytics = false;
    }

    // Set authorization header
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  } catch (error) {
    console.error('Error in request interceptor:', error);
    return config;
  }
}, (error) => {
  return Promise.reject(error);
});

export default apiClient;
