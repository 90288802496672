import React, { useRef, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import fetchMapData from "../../utility/maps/fetchMapData";
import { addMapHover } from "../../utility/maps/addMapHover";
import { addMapLayer } from "../../utility/maps/addMapLayer";
import { formatTitle } from "../../utility/formatText";
import { newtonsCradle } from "ldrs";
import { ReusableCombobox } from "../forms/ReusableCombobox";

newtonsCradle.register();

function ChoroplethMap() {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [activeLayer, setActiveLayer] = useState(null);
  const [activeMetric, setActiveMetric] = useState(null);
  const { results, loading } = useSelector((state) => state.search);
  const [isLoading, setIsLoading] = useState(false);
  const cleanupRef = useRef(null);

  // Get available metrics from results keys, excluding non-metric fields
  const availableMetrics = useMemo(() => {
    if (!results || results.length === 0) return [];
    const nonMetricFields = ["level", "geo_query", "name", "geography_pair"];
    return Object.keys(results[0]).filter(
      (key) => !nonMetricFields.includes(key)
    );
  }, [results]);

  // Get unique geography levels and set initial active layer and metric
  useEffect(() => {
    if (results && results.length > 0) {
      const firstLevel = results.find(
        (r) => r.level !== "atlas_school_district" && r.level !== "country"
      )?.level;
      setActiveLayer(firstLevel);

      // Set last available metric as active only if no metric is selected
      if (availableMetrics.length > 0 && !activeMetric) {
        const lastMetric = availableMetrics[availableMetrics.length - 1];
        setActiveMetric(lastMetric);
      }
    }
  }, [results, availableMetrics, activeMetric]);

  const handleMetricSelect = (metric) => {
    if (!metric) {
      return;
    }
    setActiveMetric(metric);
  };

  // Get unique geography levels from the results.level
  const uniqueLayers = [
    ...new Set(
      results
        ?.map((r) => r.level)
        .filter(
          (level) => level !== "atlas_school_district" && level !== "country"
        ) || []
    ),
  ];

  // Initialize map
  useEffect(() => {
    if (!map && mapContainerRef.current) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [-98.5795, 38.4283],
        zoom: 3,
      });

      // Set up load handler before setting the map
      newMap.on("load", () => {
        newMap.resize();
        setMap(newMap);
      });

      // Handle errors
      newMap.on("error", (e) => {
        console.error("Map error:", e);
      });

      // Add resize handler
      const handleResize = () => {
        if (newMap) {
          newMap.resize();
        }
      };

      // Add resize observer to handle container size changes
      const resizeObserver = new ResizeObserver(() => {
        handleResize();
      });
      resizeObserver.observe(mapContainerRef.current);

      return () => {
        resizeObserver.disconnect();
        newMap.remove();
      };
    }
  }, []); // Remove map from dependencies since we only want this to run once

  // Handle map resizing
  useEffect(() => {
    if (map && mapContainerRef.current) {
      map.resize();
    }
  }, [map]);

  // Separate effect for map updates
  useEffect(() => {
    if (!map) {
      return;
    }

    const updateMap = () => {
      if (
        !activeLayer ||
        !activeMetric ||
        !results?.length ||
        !availableMetrics?.length
      ) {
        return;
      }

      const fetchDataAndAddSource = async () => {
        setIsLoading(true);
        try {
          // Call previous cleanup function if it exists
          if (cleanupRef.current) {
            cleanupRef.current();
            cleanupRef.current = null;
          }

          // Clean up existing layers and source
          const cleanup = () => {
            if (map.getLayer("polygons-fill")) {
              map.removeLayer("polygons-fill");
            }
            if (map.getLayer("polygons-outline")) {
              map.removeLayer("polygons-outline");
            }
            if (map.getSource("polygons")) {
              map.removeSource("polygons");
            }
          };

          // Extract all geo_query values from results
          const geoQueries = results.map((result) => result.geo_query);

          // Pass geo_queries to fetchMapData
          const geojson = await fetchMapData(
            `/api/map/${activeLayer}`,
            geoQueries
          );

          // Merge search results data with geojson features
          geojson.features.forEach((feature) => {
            const result = results.find(
              (res) => res.geo_query === feature.properties.geo_query
            );
            if (result) {
              // Add all metrics to feature properties
              availableMetrics.forEach((metric) => {
                feature.properties[metric] = result[metric];
              });
            }
          });

          // Filter features to only those with data
          const featuresWithData = geojson.features.filter(
            (feature) => feature.properties[activeMetric] != null
          );

          // Calculate bounds if there are features with data
          if (featuresWithData.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();

            featuresWithData.forEach((feature) => {
              if (feature.geometry.type === "Polygon") {
                feature.geometry.coordinates[0].forEach((coord) => {
                  bounds.extend(coord);
                });
              } else if (feature.geometry.type === "MultiPolygon") {
                feature.geometry.coordinates.forEach((polygon) => {
                  polygon[0].forEach((coord) => {
                    bounds.extend(coord);
                  });
                });
              }
            });

            // Check if bounds wrap around the globe (typically due to Alaska)
            const boundsWidth =
              bounds.getNorthEast().lng - bounds.getSouthWest().lng;
            if (boundsWidth > 180) {
              // If bounds are too wide, force US bounds
              const usBounds = [
                [-125.00165, 24.9493], // Southwest coordinates
                [-66.9326, 49.5904], // Northeast coordinates
              ];
              bounds.setNorthEast(usBounds[1]);
              bounds.setSouthWest(usBounds[0]);
            }

            // Fit map to bounds with padding
            map.fitBounds(bounds, {
              padding: 50,
              maxZoom: 12, // Prevent zooming in too far
              duration: 1000, // Animation duration in milliseconds
            });
          }

          // Add source and layers
          map.addSource("polygons", {
            type: "geojson",
            data: geojson,
          });

          addMapLayer(map, geojson, activeMetric);
          const cleanupHover = addMapHover(map, availableMetrics);

          // Store the cleanup function
          cleanupRef.current = () => {
            cleanupHover();
            cleanup();
          };

          setIsLoading(false);
        } catch (error) {
          console.error("Error updating map:", error);
          setIsLoading(false);
        }
      };

      fetchDataAndAddSource();

      // Cleanup when component unmounts or when dependencies change
      return () => {
        if (cleanupRef.current) {
          cleanupRef.current();
          cleanupRef.current = null;
        }
      };
    };

    updateMap();
  }, [map, activeLayer, activeMetric, results, availableMetrics]);

  return (
    <div className="relative mt-6 mb-6 rounded-lg shadow-2xl w-full">
      {isLoading && (
        <div className="absolute inset-0 bg-style-bg/80 z-20 flex items-center justify-center">
          <l-newtons-cradle
            size="120"
            speed="1.4"
            color="black"
          ></l-newtons-cradle>
        </div>
      )}
      <div className="absolute top-2 right-2 z-20 flex flex-col gap-2">
        <div className="bg-white p-2 rounded shadow">
          <h3 className="text-sm font-semibold mb-2">Geography Level</h3>
          <ReusableCombobox
            fillColor="bg-morange-100"
            items={uniqueLayers}
            selected={activeLayer}
            onSelect={setActiveLayer}
            displayValue={(layer) => formatTitle(layer)}
            formatOption={(layer) => formatTitle(layer)}
            placeholder="Select geography level"
          />
        </div>
        <div className="bg-white p-2 rounded shadow">
          <h3 className="text-sm font-semibold mb-2">Metric</h3>
          <ReusableCombobox
            fillColor="bg-morange-100"
            items={availableMetrics}
            selected={activeMetric}
            onSelect={handleMetricSelect}
            displayValue={(metric) => formatTitle(metric)}
            formatOption={(metric) => formatTitle(metric)}
            placeholder="Select metric"
          />
        </div>
      </div>
      <div
        ref={mapContainerRef}
        className="w-full h-[650px] relative"
        style={{ minHeight: "650px", minWidth: "100%" }}
      ></div>
    </div>
  );
}

export default ChoroplethMap;
