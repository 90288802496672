import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { formatLargeNumber } from "../../utility/numberFormats";
import { formatTitle } from "../../utility/formatText";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Button } from "../forms/Button";
import { useSort } from "../../hooks/useSort";
import { useExport } from "../../hooks/useExport";
import { useColumnTypes } from "../../hooks/useColumnTypes";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({ data, columnConfig = [], showExportButton = false }) => {
  const { columnTypes } = useColumnTypes(data);
  const columns = useMemo(() => {
    const allKeys = Object.keys(data[0] || {});
    const configuredKeys = new Set(columnConfig.map((col) => col.key));

    const missingColumns = allKeys
      .filter((key) => !configuredKeys.has(key))
      .map((key) => ({ key, type: columnTypes[key] }));

    return [...columnConfig, ...missingColumns];
  }, [data, columnConfig, columnTypes]);

  const visibleColumns = useMemo(() => {
    const hrefSourceColumns = new Set(
      columns.filter((col) => col.type === "link").map((col) => col.hrefSource)
    );
    const subtitleColumns = new Set(
      columns.filter((col) => col.subtitleCol).map((col) => col.subtitleCol)
    );
    return columns.filter(
      (col) => !hrefSourceColumns.has(col.key) && !subtitleColumns.has(col.key)
    );
  }, [columns]);

  const { sortedData, sortConfig, requestSort } = useSort(data, visibleColumns);

  const columnMaxValues = useMemo(() => {
    const maxValues = {};
    if (sortedData && sortedData.length > 0) {
      visibleColumns.forEach((column) => {
        if (column.type === "numeric") {
          const maxValue = Math.max(
            ...sortedData.map((row) => row[column.key] || 0)
          );
          maxValues[column.key] = maxValue <= 100 ? 100 : maxValue;
        } else if (column.type === "percentage") {
          maxValues[column.key] = 1;
        }
      });
    }
    return maxValues;
  }, [sortedData, visibleColumns]);

  const { exportToCSV } = useExport(sortedData, visibleColumns);

  const [canScrollRight, setCanScrollRight] = useState(true);

  const tableRef = useRef(null);

  useEffect(() => {
    const element = tableRef.current;
    if (!element) return;

    const observer = new ResizeObserver(() => {
      setTimeout(() => {
        const hasHorizontalScroll =
          element.scrollWidth > element.clientWidth + 1;
        setCanScrollRight(hasHorizontalScroll);
      }, 100);
    });

    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  if (!data || data.length === 0) {
    return (
      <div className="text-center text-gray-500 py-4">No data available</div>
    );
  }

  return (
    <div className="relative w-full mb-16">
      {showExportButton && (
        <div className="flex justify-end mb-4">
          <Button
            onClick={exportToCSV}
            className="px-4 py-2 bg-style-secondary text-white rounded transition-colors duration-200"
          >
            Export to CSV
          </Button>
        </div>
      )}
      <div className="relative">
        <div className="absolute inset-y-0 -left-[50px] -right-[50px] border-t-2 border-b-2 border-mcharcoal-900 pointer-events-none">
          {canScrollRight && (
            <>
              <div className="absolute -top-[1.1rem] -right-[.9rem] text-mcharcoal-900">
                <ChevronRightIcon className="w-[2rem] h-[2rem]" />
              </div>
              <div className="absolute -top-[1.1rem] -left-[.9rem] text-mcharcoal-900">
                <ChevronLeftIcon className="w-[2rem] h-[2rem]" />
              </div>
            </>
          )}
        </div>
        <div
          className="relative w-full overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
          ref={tableRef}
        >
          <div className="min-w-full inline-block">
            <table className="w-full text-sm text-left text-gray-500">
              <TableHeader
                visibleColumns={visibleColumns}
                sortConfig={sortConfig}
                requestSort={requestSort}
              />
              <TableBody
                sortedData={sortedData}
                visibleColumns={visibleColumns}
                columnMaxValues={columnMaxValues}
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Table };
