// Generate natural language query from parameters

import { FeatureTypeConfig } from "../components/dataCatalog/config/featureTypes";

const generateQueryString = (parameters) => {
  if (
    (!parameters.metrics?.metrics || parameters.metrics.metrics.length === 0) &&
    (!parameters.filters?.filters || parameters.filters.filters.length === 0) &&
    (!parameters.places || parameters.places.length === 0)
  ) {
    return "";
  }

  // Handle metrics
  const metricsString = parameters.metrics?.metrics
    ? parameters.metrics.metrics
        .map((metric) => {
          const config = FeatureTypeConfig[metric.type];
          return config.generateQueryString(metric);
        })
        .join(" and ")
    : "registered voters";

  // Handle filters
  const filtersString = parameters.filters?.filters
    ? parameters.filters.filters
        .map((filter) => FeatureTypeConfig.filter.generateQueryString(filter))
        .join(" and ")
    : "";

  // Handle places
  const locationString =
    parameters.places?.length > 0
      ? "in " +
        parameters.places
          .map((place) => FeatureTypeConfig.places.generateQueryString(place))
          .join(" vs ")
      : "";

  const filterPhrase = filtersString ? `for ${filtersString} ` : "";

  return `${metricsString} ${filterPhrase}${locationString}`;
};

export { generateQueryString };
