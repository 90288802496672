import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { store } from "./store";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from "./Routes";
import Hotjar from "@hotjar/browser";
import { HelpBubble } from "./components/forms/HelpBubble";
import oktaAuth from './auth/oktaAuth';

function App() {
  const [shouldTrackAnalytics, setShouldTrackAnalytics] = useState(true);

  useEffect(() => {
    // Initialize dataLayer array
    window.dataLayer = window.dataLayer || [];

    // Initialize gtag with consent mode
    window.gtag = function () {
      window.dataLayer.push(arguments);
    }

    // Set default consent settings
    window.gtag('consent', 'default', {
      'analytics_storage': 'denied',
      'ad_storage': 'denied'
    });

    // Initialize GA4 with consent mode
    window.gtag('js', new Date());
    window.gtag('config', 'G-MZCHBY928Y', {
      'cookie_flags': 'SameSite=None;Secure'
    });

    const siteId = 5215222;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    // Check user email once when app loads
    oktaAuth.getUser().then((user) => {
      const isMurmurationUser = user?.email?.endsWith('@murmuration.org');
      setShouldTrackAnalytics(!isMurmurationUser);

      // Update consent if user allows tracking
      if (!isMurmurationUser) {
        window.gtag('consent', 'update', {
          'analytics_storage': 'granted'
        });
      }
    }).catch(() => {
      // If we can't get the user, default to not tracking
      setShouldTrackAnalytics(false);
    });
  }, []);

  // Updated page tracking implementation
  const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
      let isSubscribed = true;  // Track if component is mounted

      const sendPageView = () => {
        if (!window.gtag || !shouldTrackAnalytics || !isSubscribed) return;

        // Get page name from pathname
        const pageName = location.pathname.split('/').filter(Boolean).join('/') || 'home';

        // Create a user-friendly screen name
        const getScreenName = () => {
          const path = location.pathname.split('/').filter(Boolean);
          if (path.length === 0) return 'Home Page';

          return path.map(segment =>
            segment
              .split('-')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          ).join(' - ');
        };

        // Parse query parameters
        const queryParams = {};
        const searchParams = new URLSearchParams(location.search);
        for (const [key, value] of searchParams) {
          queryParams[key] = value;
        }

        const trackingData = {
          page_location: window.location.href,
          page_path: location.pathname + location.search,
          page_name: pageName,
          page_title: document.title,
          screen_name: getScreenName(),
          page_query_params: queryParams
        };

        // Send initial pageview
        window.gtag('config', 'G-MZCHBY928Y', {
          ...trackingData,
          send_page_view: true
        });

        // Send pageview event with additional data
        window.gtag('event', 'page_view', trackingData);
      };

      sendPageView();

      // Cleanup function
      return () => {
        isSubscribed = false;
      };
    }, [location, shouldTrackAnalytics]);
  };

  const PageTracker = () => {
    usePageTracking();
    return null;
  };

  return (
    <Provider store={store}>
      <Router>
        <PageTracker />
        <AppRoutes />
        <HelpBubble />
      </Router>
    </Provider>
  );
}

export default App;
