import React from "react";
import { formatTitle } from "../../../utility/formatText";
import { icons } from "../../../pages/search/config";

export const CategoryButton = ({ category, onClick }) => (
  <button
    onClick={onClick}
    className="flex flex-col items-center justify-center w-full h-24 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
  >
    {icons[category] && (
      <div className="w-8 h-8 mb-2 text-style-text-dark">{icons[category]}</div>
    )}
    <span className="mt-2">{formatTitle(category)}</span>
  </button>
);
