import React from "react";
import {
  MagnifyingGlassIcon,
  ChartBarIcon,
  ArrowDownTrayIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import { Section } from "../../../components/text/Section";
import searchGif from "./gifs/search.gif";
import dataCatalogGif from "./gifs/catalog.gif";
import mapsGif from "./gifs/map.gif";
import districtProfileGif from "./gifs/district.gif";

export const GettingStarted = () => {
  return (
    <div className="prose prose-slate max-w-none font-inter">
      {/* Introduction */}
      <Section>
        <h2 className="text-3xl font-semibold text-slate-900 mb-4">
          Getting Started with Explorer
        </h2>
        <p className="text-lg text-slate-600 leading-relaxed">
          Welcome to Explorer. This tool empowers you to access and analyze
          community-level data with ease. Below, you'll find a quick guide to
          understanding the key features and capabilities of Explorer.
        </p>
      </Section>

      {/* Key Features Grid */}
      <Section>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-slate-50 rounded-xl p-6 border border-slate-200">
            <div className="flex items-start mb-4">
              <MagnifyingGlassIcon className="h-6 w-6 text-morange-600 mr-3 flex-shrink-0" />
              <h3 className="text-lg font-semibold text-slate-900">
                Smart Search
              </h3>
            </div>
            <p className="text-slate-600 leading-relaxed">
              Use natural language to search across demographics, locations, and
              civic data with our intuitive search interface.
            </p>
          </div>

          <div className="bg-slate-50 rounded-xl p-6 border border-slate-200">
            <div className="flex items-start mb-4">
              <ChartBarIcon className="h-6 w-6 text-morange-600 mr-3 flex-shrink-0" />
              <h3 className="text-lg font-semibold text-slate-900">
                Data Visualization
              </h3>
            </div>
            <p className="text-slate-600 leading-relaxed">
              Transform complex data into clear, actionable insights with our
              interactive visualization tools.
            </p>
          </div>

          <div className="bg-slate-50 rounded-xl p-6 border border-slate-200">
            <div className="flex items-start mb-4">
              <ArrowDownTrayIcon className="h-6 w-6 text-morange-600 mr-3 flex-shrink-0" />
              <h3 className="text-lg font-semibold text-slate-900">
                Export & Share
              </h3>
            </div>
            <p className="text-slate-600 leading-relaxed">
              Download data in multiple formats and easily share insights with
              your team or stakeholders.
            </p>
          </div>

          <div className="bg-slate-50 rounded-xl p-6 border border-slate-200">
            <div className="flex items-start mb-4">
              <MapIcon className="h-6 w-6 text-morange-600 mr-3 flex-shrink-0" />
              <h3 className="text-lg font-semibold text-slate-900">
                Geographic Analysis
              </h3>
            </div>
            <p className="text-slate-600 leading-relaxed">
              Explore data through interactive maps and geographic
              visualizations at various levels of detail.
            </p>
          </div>
        </div>
      </Section>

      {/* Search Grammar Section */}
      <Section title="Grammar of Search">
        <p className="leading-relaxed mb-8">
          Explorer's search bar is designed to be intuitive and powerful,
          allowing you to find exactly what you need—even if you're unsure how
          to phrase it. Here's what you can do:
        </p>

        <div className="space-y-6">
          <ul className="list-disc space-y-4 ml-4 prose-ul:text-slate-600">
            <li className="pl-2">
              <span className="text-lg font-medium text-slate-900">
                Search by Place:
              </span>{" "}
              Look up specific places, like{" "}
              <span className="text-morange-600">"Pennsylvania,"</span> to see
              results for states, counties, cities, congressional districts,
              state upper and lower houses, zip codes, Census tracts and block
              groups, school districts, and school sub-districts.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Compare Across Locations:
              </span>{" "}
              Use phrases like{" "}
              <span className="text-morange-600">
                "Counties in Pennsylvania"
              </span>{" "}
              or{" "}
              <span className="text-morange-600">
                "Georgia vs South Carolina"
              </span>{" "}
              to view data side-by-side.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Topic-Specific Queries:
              </span>{" "}
              Explore civic or demographic data, such as{" "}
              <span className="text-morange-600">
                "Abortion support among minority voters in Pennsylvania."
              </span>
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Filters and Advanced Queries:
              </span>{" "}
              Add specificity with filters. For example, search{" "}
              <span className="text-morange-600">
                "Racial makeup of voters age 18-24 in Georgia."
              </span>
            </li>
          </ul>
        </div>

        {/* Search Grammar Formula */}
        <div className="mt-8 space-y-4">
          <p className="leading-relaxed mb-8">
            In general, you'll have the best results with your search by using
            the following grammar:
          </p>
          <div className="bg-white rounded-lg border border-slate-200 p-4">
            <code className="font-mono">
              <span className="text-blue-600">[metrics]</span> among{" "}
              <span className="text-green-600">[filters]</span> by{" "}
              <span className="text-red-600">[geography level]</span> in{" "}
              <span className="text-purple-600">[geography]</span>
            </code>
          </div>
          <div className="flex items-center">
            <div>
              For example: <span className="text-blue-600">"partisanship</span>{" "}
              <span className="text-green-600">among women</span>{" "}
              <span className="text-red-600">by school district</span>{" "}
              <span className="text-purple-600">in California"</span>
            </div>
          </div>
        </div>

        {/* Search GIF Demo */}
        <div className="mt-8 rounded-lg overflow-hidden border border-slate-200">
          <img
            src={searchGif}
            alt="Demo of Explorer search functionality"
            className="w-full h-auto"
          />
        </div>
      </Section>

      {/* Data Catalog Section */}
      <Section title="Using the Data Catalog">
        <p className="leading-relaxed mb-8">
          Not sure where to begin? The <strong>Data Catalog</strong> lets you
          browse all available metrics and refine your search with ease:
        </p>

        <div className="space-y-6">
          <ul className="list-disc space-y-4 ml-4 prose-ul:text-slate-600">
            <li className="pl-2">
              <span className="text-lg font-medium text-slate-900">
                Inventory of Selected Items:
              </span>{" "}
              Track and manage the metrics, filters, and levels you've explored.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Add or Remove Selected Items:
              </span>{" "}
              Easily adjust selected items to focus on the data you need.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Search Within the Catalog:
              </span>{" "}
              Quickly find metrics like{" "}
              <span className="text-morange-600">
                "Percent Party Democrat."
              </span>
            </li>
          </ul>
        </div>
        {/* Catalog GIF */}
        <div className="mt-8 rounded-lg overflow-hidden border border-slate-200">
          <img
            src={dataCatalogGif}
            alt="Demo of Explorer data catalog functionality"
            className="w-full h-auto"
          />
        </div>
      </Section>

      {/* Maps Section */}
      <Section title="Maps on the Search Results Page">
        <p className="leading-relaxed mb-8">
          When searching by geography type, Explorer provides interactive maps
          to complement tabular data:
        </p>

        <div className="space-y-6">
          <ul className="list-disc space-y-4 ml-4 prose-ul:text-slate-600">
            <li className="pl-2">
              <span className="text-lg font-medium text-slate-900">
                Dynamic Visualization:
              </span>{" "}
              Maps display the data geographically, making it easy to spot
              patterns and trends.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Toggle Metrics and Layers:
              </span>{" "}
              Switch between metrics to visualize different datasets, or adjust
              geography layers for more granular views.
            </li>
          </ul>
        </div>

        <p className="leading-relaxed mt-8">
          Maps help you connect the numbers in the table to their real-world
          geographical context, providing a more intuitive way to analyze data.
        </p>
        {/* Map GIF */}
        <div className="mt-8 rounded-lg overflow-hidden border border-slate-200">
          <img
            src={mapsGif}
            alt="Demo of Explorer map functionality"
            className="w-full h-auto"
          />
        </div>
      </Section>

      {/* District Profile Section */}
      <Section title="The District Profile Page">
        <p className="leading-relaxed mb-8">
          Dive deeper into any location by clicking on its name in the search
          results. Each District Profile Page includes:
        </p>

        <div className="space-y-6">
          <ul className="list-disc space-y-4 ml-4 prose-ul:text-slate-600">
            <li className="pl-2">
              <span className="text-lg font-medium text-slate-900">
                Overview and AI-Generated Description:
              </span>{" "}
              Get a summary of the district's location and political context.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Demographics Tab:
              </span>{" "}
              View topline charts showing population makeup.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">
                Turnout Scores:
              </span>{" "}
              See turnout projections by election type and demographic group.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">Vote History:</span>{" "}
              Analyze historical turnout trends since 2016.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">Model Scores:</span>{" "}
              Explore distributions and averages for various scores, like
              charter support or partisanship.
            </li>
            <li className="pl-2">
              <span className="font-medium text-slate-900">Early Vote:</span>{" "}
              Access early vote data.
            </li>
          </ul>
        </div>

        <p className="leading-relaxed mt-8">
          You can compare districts directly from this page to better understand
          differences and similarities.
        </p>
        {/* District Profile GIF */}
        <div className="mt-8 rounded-lg overflow-hidden border border-slate-200">
          <img
            src={districtProfileGif}
            alt="Demo of Explorer district profile functionality"
            className="w-full h-auto"
          />
        </div>
      </Section>

      {/* Search Examples */}
      <Section title="Start Exploring" isLast={true}>
        <p className="text-lg text-slate-600 leading-relaxed mt-8 mb-6">
          Ready to get started? Try these sample searches to see the power of
          Explorer:
        </p>
        <div className="rounded-xl border border-slate-200 overflow-hidden">
          <div className="bg-slate-50 px-6 py-4 border-b border-slate-200">
            <h3 className="text-lg font-semibold text-slate-900">
              Example Searches
            </h3>
          </div>
          <div className="p-6 space-y-4">
            <div className="bg-morange-50 text-morange-700 rounded-lg p-3 font-mono text-sm">
              "Voter turnout in Fulton County, GA congressional districts"
            </div>
            <div className="bg-morange-50 text-morange-700 rounded-lg p-3 font-mono text-sm">
              "College education among rural voters in Southern counties"
            </div>
            <div className="bg-morange-50 text-morange-700 rounded-lg p-3 font-mono text-sm">
              "Racial makeup of low turnout voters in school districts in Texas"
            </div>
          </div>
        </div>
        <p className="text-lg text-slate-600 leading-relaxed mt-8">
          For detailed analysis, you can also export data to Excel and create
          custom visualizations.
        </p>
      </Section>
    </div>
  );
};
