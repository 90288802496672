import React from "react";
import { formatTitle } from "../../../utility/formatText";

export const BackButton = ({ onClick, backTarget }) => (
  <button
    onClick={onClick}
    className="mb-4 px-4 py-2 font-inter text-style-text-dark shadow-md text-sm rounded-lg bg-style-surface border-none hover:bg-style-bg"
  >
    Back to {formatTitle(backTarget)}
  </button>
);
