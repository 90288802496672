// frontend/src/components/dataCatalog/tabs/FiltersTab.js
import React, { useState } from "react";
import { newtonsCradle } from "ldrs";
import { CategoryButton } from "../components/CategoryButton";
import { FeatureButton } from "../components/FeatureButton";
import { BackButton } from "../components/BackButton";
import { CatalogSearchBar } from "../components/CatalogSearchBar";
import { useFeatureNavigation } from "../../../utility/dataCatalog/useFeatureNavigation";
import { useFetchFeatures } from "../../../utility/useFetchFeatures";
import { filterFeatures } from "../../../utility/dataCatalog/filterFeatures";
import { FeatureTypeConfig } from "../config/featureTypes";

newtonsCradle.register();

const FiltersTab = ({ onFilterSelect, isItemSelected }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    selectedCategory,
    selectedFeature,
    handleCategoryClick,
    handleFeatureClick,
    handleBack,
    handleDirectCategoryAndFeature,
    isDirectNavigation,
  } = useFeatureNavigation();

  const { categorizedFeatures, isLoading } = useFetchFeatures(
    "/api/config/filters"
  );
  const filteredFeatures = filterFeatures(categorizedFeatures, searchTerm);

  const handleFilterSelection = (filterItem) => {
    onFilterSelect(filterItem);
  };

  const handleCategoryOrFeatureClick = (category, features) => {
    if (features.length === 1 && features[0].name === category) {
      handleDirectCategoryAndFeature(category, features[0]);
    } else {
      handleCategoryClick(category);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <l-newtons-cradle
          size="120"
          speed="1.4"
          color="black"
        ></l-newtons-cradle>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {!selectedFeature && (
        <CatalogSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}

      {selectedCategory && (
        <BackButton
          onClick={handleBack}
          backTarget={
            selectedFeature && !isDirectNavigation
              ? selectedCategory
              : "Categories"
          }
        />
      )}

      {!selectedCategory ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {Object.entries(filteredFeatures).map(([category, features]) => (
            <CategoryButton
              key={category}
              category={category}
              onClick={() => handleCategoryOrFeatureClick(category, features)}
            />
          ))}
        </div>
      ) : !selectedFeature ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {filteredFeatures[selectedCategory]?.map((feature) => (
            <FeatureButton
              key={feature.name}
              feature={feature.display || feature.name}
              description={feature.description}
              onClick={() => handleFeatureClick(feature)}
              selected={false}
            />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {selectedFeature.values.map((value, idx) => {
            const config = FeatureTypeConfig["filter"];
            const filterItem = config.createFilterItem(
              selectedFeature,
              value,
              selectedFeature.display_values[idx]
            );
            const selected = isItemSelected(filterItem, "filters");
            return (
              <FeatureButton
                key={value}
                feature={selectedFeature.display_values[idx]}
                description={selectedFeature.description}
                onClick={() => handleFilterSelection(filterItem)}
                selected={selected}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export { FiltersTab };
