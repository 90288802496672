const inBriefCategories = ["atlas_contacts", "voter_turnout"];
const inBriefDataFormat = ["number", "percent"];

const turnoutScores = [
  "presidential_general_turnout",
  "midterm_general_turnout",
  "presidential_primary_turnout",
  "non_presidential_primary_turnout",
  "offyear_general_turnout",
];

const scores = [
  "partisan",
  "activist",
  "big_changes_in_ed",
  "biden_support",
  "campaign_finance",
  "cellular_phone_contactability",
  "charter_support",
  "children_present",
  "civic_volunteer",
  "climate_change",
  "college_graduate",
  "community_engagement",
  "democracy_protection",
  "downballot_completion",
  "education_voter",
  "frequent_religious_attender",
  "grassroots_engagement",
  "gun_control",
  "gunowner",
  "high_school_only",
  "ideology_enhanced",
  "income_rank",
  "labor_union_support",
  "landline_phone_contactability",
  "local_voter",
  "marriage_equality",
  "midterm_general_turnout",
  "minimum_wage",
  "murms_spanish_speaking",
  "non_presidential_primary_turnout",
  "offyear_general_turnout",
  "paid_leave",
  "path_to_citizen",
  "presidential_general_turnout",
  "presidential_primary_turnout",
  "progressive_tax",
  "rank_choice_voting_support",
  "redistrict_independent",
  "reproductive_rights_support",
  "reproductive_rights_voter",
  "social_engagement",
  "third_party_support",
  "trump_support",
  "veteran",
];

const scoreDescriptions = {
  partisan:
    "The Partisan Score indicates the likelihood an individual identifies as a Democrat. Higher scores (up to 100) indicate a higher likelihood of holding this identity. Lower scores indicate likelihood an individual identifies as a Republican. This score is only available to 501(c)(4) partners.",

  ideology_enhanced:
    "The Ideology Enhanced Score indicates the likelihood that an individual supports liberal causes. Higher scores (up to 100) suggest more support for liberal causes. A lower score means an individual leans less liberal, rather than more conservative. This score is only available to 501(c)(4) partners.",

  biden_support:
    "The Biden Support Score indicates the likelihood that an individual supports Joe Biden. Individuals with a higher score are more likely to support Joe Biden. This score is only available to 501(c)(4) partners.",

  trump_support:
    "The Trump Support Score indicates the likelihood that an individual supports Donald Trump. Individuals with a higher score are more likely to support Trump. This score is only available to 501(c)(4) partners.",

  progressive_tax:
    "The Progressive Tax Score indicates the likelihood that an individual supports a progressive income-tax system, with higher taxes on wealthy individuals. Individuals with a higher score are more likely to support a progressive income-tax.",

  gun_control:
    "The Gun Control Score indicates the likelihood that an individual supports new, aggressive gun control laws. Individuals with a higher score are more likely to support gun control laws.",

  gunowner:
    "The Gun Owner Score indicates the likelihood that an individual is a gun owner. Individuals with a higher score are more likely to own guns.",

  rank_choice_voting_support:
    "The Ranked Choice Voting Support Score indicates the likelihood that an individual supports electoral systems that allow voters to rank candidates in order of preference. Individuals with a higher score are more likely to support ranked choice voting.",

  campaign_finance:
    "The Campaign Finance Score indicates the likelihood that an individual supports campaign finance reform. Individuals with a higher score are more likely to support this reform.",

  democracy_protection:
    "The Democracy Protection Score indicates a person's likelihood to view the events at the Capitol on January 6th 2021 as a violent riot against our government and support a candidate who shares their views on democracy.",

  redistrict_independent:
    "The Independent Redistricting Support Score indicates the likelihood that an individual prefers that redistricting, or the drawing of congressional and legislative boundaries is done by independent commissions as opposed to state legislatures. Individuals with a higher score are more likely to support redistricting by independent commissions.",

  minimum_wage:
    "The Minimum Wage Score indicates the likelihood that an individual supports a $15 minimum wage. Individuals with a higher score are more likely to support a $15 minimum wage.",

  labor_union_support:
    "The Labor Union Support Score indicates an individual's support of labor unions. Individuals with a higher score are more likely to support labor unions.",

  charter_support:
    "The Charter Support Score indicates the likelihood that a voter supports charter schools. Voters with a higher score are more likely to support charter schools.",

  big_changes_in_ed:
    "The Big Changes in Education Score indicates the likelihood that a voter wants to see big changes in public education. Voters with a higher score are more likely to support big changes in the public school system.",

  education_voter:
    "The Education Voter Score indicates the likelihood that education is a voter's most important issue when they go to the polls. Voters with a higher score are more likely to be education motivated.",

  climate_change:
    "The Climate Change Score indicates the likelihood that an individual believes that action to reverse climate change should be a top priority for the President and Congress. Individuals with a higher score are more likely to hold this belief.",

  marriage_equality:
    "The Marriage Equality Score indicates the likelihood that an individual supports the legality of same-sex marriage. Individuals with a higher score are more likely to support same-sex marriage.",

  reproductive_rights_voter:
    "The Reproductive Rights Voter Score indicates a person's likelihood to believe the federal government should allow abortions in all or most cases and they would be willing to vote for a candidate from a different party or abstain from voting when a candidate from their own party does not share their view.",

  reproductive_rights_support:
    "The Reproductive Rights Support Score indicates a person's likelihood to believe the federal government should allow abortions in all or most cases.",

  college_graduate:
    "The College Graduate Score indicates the likelihood that an individual graduated from a college or university with a Bachelor's degree. Individuals with a higher score are more likely to be a college graduate.",

  high_school_only:
    "The High School Only Score indicates the likelihood that an individual has a high school education or less. Individuals with a higher score are more likely to have a high school level education.",

  children_present:
    "The Children Present Score indicates the likelihood that an individual lives in a household with children. Individuals with a higher score are more likely to live in a household with children.",

  income_rank:
    "The Income Rank Score rank orders individuals based on their estimated income level. Individuals with a higher score are more likely to have a higher income.",

  veteran:
    "The Veteran Score indicates an individual's likelihood to be a military veteran or active service member. Individuals with a higher score are more likely to be a military veteran or active service member.",

  frequent_religious_attender:
    "The Frequent Religious Attender Score indicates a person's likelihood to attend a religious service at least once a week.",

  activist:
    "The Activist Score indicates a person's likelihood to be a political activist. A higher score corresponds to a greater likelihood of being an activist, defined as a person who has performed political acts such as volunteering on a campaign, demonstrating, going to a town hall, reaching out to elected officials, or donating money to campaigns.",

  grassroots_engagement:
    "The Grassroots Engagement Score indicates a person's likelihood to contact an elected official or donate to a state political campaign.",

  community_engagement:
    "The Community Engagement Score indicates a person's likelihood to attend a public meeting, volunteer with, or hold a leadership position in a community organization.",

  civic_volunteer:
    "The Civic Volunteer Score indicates a person's likelihood to volunteer on behalf of a national, state, local city, or neighborhood political campaign.",

  social_engagement:
    "The Social Engagement Score indicates a person's likelihood to attend a protest/rally, convince others to volunteer, or share/discuss issues on social media.",

  local_voter:
    "The Local Voter Score predicts a voter's likelihood of voting in local elections where local offices are the highest on the ballot.",

  cellular_phone_contactability:
    "The Cellular Phone Contactability Score indicates the likelihood of successfully contacting an individual by cellular phone.",

  landline_phone_contactability:
    "The Landline Phone Contactability Score indicates the likelihood of successfully contacting an individual by landline phone.",

  paid_leave:
    "The Paid Leave Score indicates the likelihood that an individual supports the requirements of paid leave policies from all employers. Individuals with a higher score are more likely to support paid leave policies.",

  path_to_citizen:
    "The Path to Citizen Score indicates the likelihood that an individual supports a pathway to citizenship. Individuals with a higher score are more likely to support a pathway to citizenship.",

  third_party_support:
    "The Moderate Third Party Support Score indicates the likelihood that an individual believes the Democratic and Republican parties have become too extreme and there is a need for a new moderate party. Individuals with a higher score are more likely to support a moderate third party.",

  murms_spanish_speaking:
    "The Spanish Speaking Score indicates the likelihood that a voter speaks Spanish. Voters with a higher score are more likely to speak Spanish.",

  non_presidential_primary_turnout:
    "This score predicts a voter's likelihood of voting in non-presidential primary elections.",

  presidential_primary_turnout:
    "This score predicts a voter's likelihood of voting in presidential primary elections.",

  presidential_general_turnout:
    "This score predicts a voter's likelihood of voting in presidential general elections.",

  midterm_general_turnout:
    "This score predicts a voter's likelihood of voting in midterm general elections.",

  offyear_general_turnout:
    "This score predicts a voter's likelihood of voting in off-year general elections.",

  downballot_completion:
    "The Down Ballot Completion Score indicates a person's likelihood to vote in local races and ballot initiatives.",
};

const contextBarConfig = {
  sections: {
    overview: {
      id: "overview",
      component: "GeographyDescription",
    },
    turnout_scores: {
      id: "turnout_scores",
      component: "TurnoutScoreContext",
    },
    vote_history: {
      id: "vote_history",
      component: "DemographicFilters",
      title: "Vote History: Closer Look",
      subtitle: "Select a demographic group to filter.",
      filters: [
        {
          name: "Age",
          options: [
            { label: "18 to 24", color: "purple" },
            { label: "25 to 34", color: "red" },
            { label: "35 to 44", color: "green" },
            { label: "45 to 64", color: "pink" },
            { label: "65 to 84", color: "blue" },
            { label: "85+", color: "indigo" },
          ],
          footnote: "Note: Age groups are based on their age as of today.",
        },
        {
          name: "Ethnicity",
          options: [
            { label: "Asian", color: "purple" },
            { label: "Black", color: "red" },
            { label: "Hispanic", color: "green" },
            // { label: "Native American", color: "pink" },
            { label: "White", color: "blue" },
          ],
          footnote: "Note: This is modeled in most states.",
        },
        {
          name: "Gender",
          options: [
            { label: "Male", color: "blue" },
            { label: "Female", color: "green" },
          ],
        },
      ],
    },
    scores: {
      id: "scores",
      component: "ScoreContextBar",
    },
    early_vote: {
      id: "early_vote",
      component: "EarlyVoteContext",
    },
  },
};

const civicPulseConfig = {
  id: "civic_pulse",
  component: "CivicPulseSection",
  title: "Civic Pulse",
  subtitle: "Explore public opinion on various topics.",
  questions: [
    {
      id: "immigrants",
      question: "Which statement is closer to your view on recent immigrants?",
      answers: [
        { id: "answer1", text: "Recent immigrants STRENGTHEN our country" },
        { id: "answer2", text: "Recent immigrants BURDEN our country" },
        { id: "answer3", text: "Neither" },
        { id: "answer4", text: "Don't know" },
      ],
    },
    {
      id: "voting",
      question:
        "Thinking about voting for president, governor, and other offices in the US, do you think voting is difficult or easy?",
      answers: [
        { id: "answer1", text: "Very difficult" },
        { id: "answer2", text: "Somewhat difficult" },
        { id: "answer3", text: "Somewhat easy" },
        { id: "answer4", text: "Very easy" },
      ],
    },
    {
      id: "abortion",
      question: "Which statement is closest to your view on abortion?",
      answers: [
        { id: "answer1", text: "Abortion should be legal in all cases" },
        { id: "answer2", text: "Abortion should be legal in most cases" },
        { id: "answer3", text: "Abortion should be illegal in most cases" },
        { id: "answer4", text: "Abortion should be illegal in all cases" },
      ],
    },
    {
      id: "guns",
      question:
        "Which of the following statements comes closest to your view on the laws concerning the ownership and use of guns?",
      answers: [
        { id: "answer1", text: "Gun laws should be MORE STRICT" },
        { id: "answer2", text: "Gun laws are ABOUT RIGHT" },
        { id: "answer3", text: "Gun laws should be LESS STRICT" },
        { id: "answer4", text: "Don't know" },
      ],
    },
    {
      id: "climate",
      question:
        "To what extent does climate change affect your quality of life?",
      answers: [
        { id: "answer1", text: "A great deal" },
        { id: "answer2", text: "A fair amount" },
        { id: "answer3", text: "Only a little" },
        { id: "answer4", text: "Not at all" },
      ],
    },
  ],
};

const demographicChartTypes = {
  gender: "bar",
  party: "bar",
  ethnicity: "bar",
  // education: "pie",
  marital_status: "bar",
  age: "bar",
  partisan_bucket: "bar",
  // income: "pie",
  urbanicity: "bar",
  presidential_general_turnout_bucket: "bar",
  midterm_general_turnout_bucket: "bar",
  offyear_general_turnout_bucket: "bar",
};

const demographicCategories = Object.keys(demographicChartTypes);

const sections = {
  overview: {
    id: "overview",
    navName: "Overview",
    level: 1,
  },
  demographics: {
    id: "demographics",
    navName: "Demographics",
    level: 1,
  },
  ...demographicCategories.reduce((acc, category) => {
    acc[category] = {
      id: category,
      navName: category
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      level: 2,
    };
    return acc;
  }, {}),
  turnout_scores: {
    id: "turnout-scores",
    navName: "Turnout Scores",
    level: 1,
  },
  turnout_scores_toplines: {
    id: "turnout-score-toplines",
    navName: "Toplines",
    level: 2,
  },
  turnout_scores_breakout: {
    id: "turnout-score-breakout",
    navName: "Breakout",
    level: 2,
  },
  vote_history: {
    id: "vote_history",
    navName: "Vote History",
    level: 1,
  },
  general: {
    id: "general",
    navName: "General",
    level: 2,
  },
  primary: {
    id: "primary",
    navName: "Primary",
    level: 2,
  },
  minor: {
    id: "minor",
    navName: "Minor",
    level: 2,
  },
  scores: {
    id: "scores",
    navName: "Scores",
    level: 1,
  },
  score_distribution: {
    id: "score-distribution",
    navName: "Distribution",
    level: 2,
  },
  score_demographics: {
    id: "score-demographics",
    navName: "Demographics",
    level: 2,
  },
  civic_pulse: {
    id: "civic-pulse",
    navName: "Civic Pulse",
    level: 1,
  },
  early_vote: {
    // {{ edit_1 }}
    id: "early-vote",
    navName: "Early Vote",
    level: 1,
  },
  ...civicPulseConfig.questions.reduce((acc, question) => {
    acc[`civic-pulse-${question.id}`] = {
      id: `civic-pulse-${question.id}`,
      navName: question.id
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      level: 2,
    };
    return acc;
  }, {}),
};

export {
  sections,
  demographicCategories,
  demographicChartTypes,
  contextBarConfig,
  turnoutScores,
  scores,
  scoreDescriptions,
  civicPulseConfig,
};
