// Add hover functionality, including a tooltip, to the Mapbox map

import mapboxgl from "mapbox-gl";
import { formatTitle } from "../formatText";
import { formatLargeNumber } from "../numberFormats";

const formatValue = (value, metric) => {
  if (value === undefined || value === null) return "No data";
  if (value <= 1) {
    return `${Math.round(value * 100)}%`;
  }
  return formatLargeNumber(value);
};

export const addMapHover = (map, metrics) => {
  let hoveredPolygonId = null;
  let selectedPolygonId = null;
  let isTooltipFrozen = false;

  let popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    maxWidth: "none",
  });

  // Helper function to show tooltip
  const showTooltip = (feature, lngLat) => {
    const name = feature.properties.name;
    const metricsHtml = metrics
      .map((metric) => {
        const value = feature.properties[metric];
        const formattedValue = formatValue(value, metric);
        return `
          <div class="py-2 border-b border-slate-200 last:border-b-0">
            <div class="text-xs text-slate-600 font-medium">
              ${formatTitle(metric)}
            </div>
            <div class="text-sm font-semibold text-slate-800">
              ${formattedValue}
            </div>
          </div>
        `;
      })
      .join("");

    const html = `
      <div class="relative pr-8 pt-6">
        <button onclick="closePopup()" 
                class="absolute -top-1 right-0 p-2 text-2xl leading-none text-slate-400 hover:text-slate-600 transition-colors focus:outline-none">
          ×
        </button>
        <div class="flex flex-col gap-1 pb-2 mb-2 border-b border-slate-300">
          <div class="text-lg font-bold text-slate-900">
            ${name}
          </div>
          <a href="/district-profile?${feature.properties.geo_query}" 
             target="_blank"
             rel="noopener noreferrer"
             class="text-sm text-morange-600 hover:text-morange-700 font-medium">
            View District Profile →
          </a>
        </div>
        <div class="space-y-1">
          ${metricsHtml}
        </div>
      </div>
    `;

    // Add closePopup function to window scope
    window.closePopup = () => {
      popup.remove();

      // Force remove any remaining popup elements
      const popupElements = map
        .getContainer()
        .getElementsByClassName("mapboxgl-popup");
      Array.from(popupElements).forEach((el) => el.remove());

      isTooltipFrozen = false;

      // Clear hover state
      if (hoveredPolygonId !== null) {
        map.setFeatureState(
          { source: "polygons", id: hoveredPolygonId },
          { hover: false }
        );
        hoveredPolygonId = null;
      }

      // Clear selection state
      if (selectedPolygonId !== null) {
        map.setFeatureState(
          { source: "polygons", id: selectedPolygonId },
          { selected: false }
        );
        selectedPolygonId = null;
      }
    };

    popup.setLngLat(lngLat).setHTML(html).addTo(map);
  };

  // Add click handler to freeze tooltip
  map.on("click", "polygons-fill", (e) => {
    if (e.features.length > 0) {
      e.preventDefault();
      popup.remove();

      // Clear previous selection
      if (selectedPolygonId !== null) {
        map.setFeatureState(
          { source: "polygons", id: selectedPolygonId },
          { selected: false }
        );
      }

      // Set new selection
      selectedPolygonId = e.features[0].properties.geoid;
      map.setFeatureState(
        { source: "polygons", id: selectedPolygonId },
        { selected: true }
      );

      isTooltipFrozen = true;
      showTooltip(e.features[0], e.lngLat);
    }
  });

  map.on("mousemove", "polygons-fill", (e) => {
    if (e.features.length > 0 && !isTooltipFrozen) {
      const feature = e.features[0];

      // Remove existing popup first
      popup.remove();

      if (hoveredPolygonId !== null) {
        map.setFeatureState(
          { source: "polygons", id: hoveredPolygonId },
          { hover: false }
        );
      }
      hoveredPolygonId = feature.properties.geoid;
      map.setFeatureState(
        { source: "polygons", id: hoveredPolygonId },
        { hover: true }
      );

      map.getCanvas().style.cursor = "pointer";
      showTooltip(feature, e.lngLat);
    }
  });

  map.on("mouseleave", "polygons-fill", () => {
    if (!isTooltipFrozen) {
      if (hoveredPolygonId !== null) {
        map.setFeatureState(
          { source: "polygons", id: hoveredPolygonId },
          { hover: false }
        );
      }
      hoveredPolygonId = null;
      map.getCanvas().style.cursor = "";
      popup.remove();
    }
  });

  // Cleanup function
  return () => {
    try {
      popup?.remove();
      // Force remove any remaining popup elements
      const popupElements = map
        .getContainer()
        .getElementsByClassName("mapboxgl-popup");
      Array.from(popupElements).forEach((el) => el.remove());
      // Remove the global closePopup function
      window.closePopup = undefined;
    } catch (e) {
      // Ignore popup removal errors
    }
    map.off("mousemove", "polygons-fill");
    map.off("mouseleave", "polygons-fill");
    map.off("click", "polygons-fill");
  };
};
