import React from "react";
import useFetch from "../../hooks/fetchData";
import { useSelector } from "react-redux";
import { colors } from "../../utility/colors";

const TrueViewsToplines = ({ endpoint, comparisonEndpoint }) => {
  const { data, loading } = useFetch(`${endpoint}&year=2024`);
  const { data: comparisonData, loading: comparisonLoading } = useFetch(
    comparisonEndpoint ? `${comparisonEndpoint}&year=2024` : null
  );
  const districtInfo = useSelector((state) => state.districtPage.districtInfo);
  const comparisonInfo = useSelector(
    (state) => state.districtPage.comparisonInfo
  );

  if (loading || !data || data.length === 0) return null;

  const value = data[0].value * 100;

  return (
    <div className="text-lg tracking-tight font-light">
      <span>
        <div
          className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full"
          style={{
            backgroundColor: colors.districtBadge.background,
          }}
        >
          <span
            className="text-2xl"
            style={{ color: colors.districtBadge.text }}
          >
            {value.toFixed(1)}%
          </span>
        </div>{" "}
        {!comparisonLoading && comparisonData && comparisonData.length > 0 && (
          <>
            {"vs "}
            <div
              className="inline-flex items-center gap-1 px-2 py-0.5 rounded-full"
              style={{
                backgroundColor: colors.comparisonBadge.background,
              }}
            >
              <span
                className="text-2xl"
                style={{ color: colors.comparisonBadge.text }}
              >
                {(comparisonData[0].value * 100).toFixed(1)}%
              </span>
            </div>{" "}
          </>
        )}
        support.
      </span>
    </div>
  );
};

export { TrueViewsToplines };
