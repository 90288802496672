import React, { useMemo } from "react";
import { Text } from "@visx/text";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AxisBottom } from "@visx/axis";
import { withParentSize } from "@visx/responsive";
import { hatch } from "ldrs";
import { Circle } from "@visx/shape";
import { AnimatedText } from "./Bars.js";
import { animated, useSpring } from "@react-spring/web";

hatch.register();

const { colors } = require("../../utility/colors.js");

const horizontalMargin = 40;
const verticalMargin = 30;

const AnimatedCircle = ({ cx, cy, r, fill }) => {
  const springProps = useSpring({
    from: { cx: 0, opacity: 0 }, // Start from left side (x=0) and transparent
    to: { cx, opacity: 1 }, // Animate to final position and full opacity
    config: { tension: 120, friction: 14 }, // Adjust these values for different animation feels
  });

  return (
    <animated.circle
      cx={springProps.cx}
      cy={cy}
      r={r}
      fill={fill}
      opacity={springProps.opacity}
    />
  );
};

function CoreSupportOpposeChart({
  data = null,
  comparisonData = null,
  width = null,
  height = null,
  parentHeight = null,
  parentWidth = null,
  primaryColor = colors.chartColors["primary"],
  comparisonColor = colors.chartColors["secondary"],
  dataLabelFormatter = (value) => `${value}%`,
  loading = false,
  valueFontSize = 14,
}) {
  // Set the width and height
  width = width || parentWidth;
  height = height || parentHeight;

  // Set the margins
  const xMax = width - 2 * horizontalMargin;
  const yMax = height - verticalMargin;

  // Create the x scale for 0-100 range
  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        domain: [0, 100],
      }),
    [xMax]
  );

  // Calculate vertical positions for multiple items
  const itemHeight = Math.min(50, (yMax - verticalMargin) / 1);
  const getYPosition = (index) => verticalMargin / 2 + index * itemHeight;

  if (loading) {
    return (
      <div className={`relative h-[${height}px]`}>
        <div
          className={`w-full h-[${height}px] flex items-center justify-center absolute`}
        >
          <l-hatch size="32" color="#334155"></l-hatch>
        </div>
      </div>
    );
  }

  return width < 10 || height < 10 ? null : (
    <>
      <svg width={width} height={height} className="visx-chart-svg">
        <Group left={horizontalMargin}>
          {/* Draw axis lines for each item */}
          <React.Fragment key={`axis-${data}`}>
            <line
              x1={0}
              x2={xMax}
              y1={yMax}
              y2={yMax}
              stroke={colors.mcharcoal["700"]}
              strokeWidth={2}
            />
            <line
              x1={0}
              x2={0}
              y1={yMax + 5}
              y2={yMax - 5}
              stroke={colors.mcharcoal["700"]}
              strokeWidth={2}
            />
            <line
              x1={xMax}
              x2={xMax}
              y1={yMax + 5}
              y2={yMax - 5}
              stroke={colors.mcharcoal["700"]}
              strokeWidth={2}
            />
            {/* Comparison dot if exists */}
            {comparisonData && (
              <>
                <AnimatedCircle
                  key={`comparison-${comparisonData}`}
                  cx={xScale(comparisonData)}
                  cy={yMax}
                  r={9}
                  fill={comparisonColor}
                />
              </>
            )}

            {/* Primary dot */}
            <AnimatedCircle
              key={`primary-${data}`}
              cx={xScale(data)}
              cy={yMax}
              r={9}
              fill={primaryColor}
            />
          </React.Fragment>
          {/* Axis labels */}
          <Text
            x={0}
            y={yMax + 10}
            fill={colors.mcharcoal["700"]}
            fontSize={12}
            textAnchor="middle"
            verticalAnchor="start"
            fontFamily="Inter, sans-serif"
          >
            Oppose
          </Text>
          <Text
            x={xMax}
            y={yMax + 10}
            fill={colors.mcharcoal["700"]}
            fontSize={12}
            textAnchor="middle"
            verticalAnchor="start"
            fontFamily="Inter, sans-serif"
          >
            Support
          </Text>
        </Group>
      </svg>
    </>
  );
}

const SupportOpposeChart = withParentSize(CoreSupportOpposeChart);
export default SupportOpposeChart;
export { SupportOpposeChart };
