import { colors } from "./colors";

// Colors for support/oppose text
export const OPPOSE_COLOR = colors.chartColors.oppose;
export const SUPPORT_COLOR = colors.chartColors.support;

// Function to format title from snake_case to Title Case
export const formatTitle = (text) => {
  if (text === null) return "";
  return text
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Function to color-code support/oppose text
export const colorCodeText = (text) => {
  if (!text) return null;

  const cleanedText = text.replace(/,\s*/g, " ");

  const parts = cleanedText.split(/(support|oppose|agree|disagree)/i);
  return (
    <span>
      {parts.map((part, i) => {
        const lowerPart = part.toLowerCase();
        if (lowerPart === "support" || lowerPart === "agree") {
          return (
            <span key={i} style={{ color: SUPPORT_COLOR }}>
              {part}
            </span>
          );
        } else if (lowerPart === "oppose" || lowerPart === "disagree") {
          return (
            <span key={i} style={{ color: OPPOSE_COLOR }}>
              {part}
            </span>
          );
        }
        return part;
      })}
    </span>
  );
};
