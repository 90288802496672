import React from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const TableHeader = ({ visibleColumns, sortConfig, requestSort }) => {
  const formatColumnHeader = (column) => {
    if (column.key === "Registered Voters") {
      return (
        <>
          <div>Registered Voters</div>
          <div className="text-[10px] text-mcharcoal-500">Total</div>
        </>
      );
    } else if (
      column.key.startsWith("avg") ||
      column.key.startsWith("percent")
    ) {
      return (
        <>
          <div>
            {column.key
              .replace("avg_", "")
              .replace("percent_", "")
              .replace(/_/g, " ")}
          </div>
          <div className="text-[10px] text-mcharcoal-500">
            {column.type === "percentage" ? "Percent" : "Average"}
          </div>
        </>
      );
    } else if (column.key.startsWith("sum") || column.key.startsWith("count")) {
      return (
        <>
          <div>
            {column.key
              .replace("sum_", "")
              .replace("count_", "")
              .replace(/_/g, " ")}
          </div>
          <div className="text-[10px] text-mcharcoal-500">Total</div>
        </>
      );
    } else {
      return <div>{column.key.replace(/_/g, " ")}</div>;
    }
  };

  return (
    <thead className="text-xs font-inter text-mcharcoal-900 uppercase">
      <tr>
        {visibleColumns.map((column) => (
          <th
            key={column.key}
            scope="col"
            className={`tracking-wider px-6 py-3 cursor-pointer min-w-[8rem] ${
              column.type === "numeric" || column.type === "percentage"
                ? "text-right max-w-[8rem]"
                : ""
            }`}
            onClick={() => requestSort(column.key)}
          >
            <div
              className={`flex items-center relative ${
                column.type === "numeric" || column.type === "percentage"
                  ? "justify-end"
                  : "justify-between"
              }`}
            >
              <div className="flex flex-col">{formatColumnHeader(column)}</div>
              {sortConfig.key === column.key && (
                <span className="absolute right-0 translate-x-5">
                  {sortConfig.direction === "ascending" ? (
                    <ChevronUpIcon className="w-4 h-4 ml-1" />
                  ) : (
                    <ChevronDownIcon className="w-4 h-4 ml-1" />
                  )}
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
