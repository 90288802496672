import { useCallback } from "react";

export const useExport = (sortedData, visibleColumns) => {
  const convertToCSV = useCallback(() => {
    const headers = visibleColumns.map((col) => col.key);
    const csvRows = [
      headers.join(","),
      ...sortedData.map((row) =>
        headers
          .map((header) => {
            const escaped = `${row[header]}`.replace(/"/g, '""');
            return `"${escaped}"`;
          })
          .join(",")
      ),
    ];
    return csvRows.join("\n");
  }, [sortedData, visibleColumns]);

  const exportToCSV = useCallback(() => {
    const csv = convertToCSV();
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  }, [convertToCSV]);

  return { exportToCSV };
};
