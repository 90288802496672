import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchResults,
  resetParameters,
  clearError,
  setQuery,
} from "../../store/searchPage";
import { Table } from "../../components/tables/Table";
import TableParameters from "../../components/tables/TableParameters";
import SearchBar from "../../components/search/SearchBar";
import { newtonsCradle } from "ldrs";
import ChoroplethMap from "../../components/maps/ChoroplethMap";
import { TableCellsIcon, MapIcon } from "@heroicons/react/20/solid";

newtonsCradle.register();

const SearchResults = ({ queryString = null }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const query = queryString || searchParams.get("query");

  // Get initial view from URL or default to "table"
  const initialView = searchParams.get("view") || "table";
  const [view, setView] = useState(initialView);

  const { results, loading, parameters, error, parsingStatus } = useSelector(
    (state) => state.search
  );

  // Update URL when view changes
  const handleViewChange = (newView) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("view", newView);
    // Update URL without reloading the page
    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      replace: true,
    });
    setView(newView);
  };

  useEffect(() => {
    const queryParam = searchParams.get("query");
    if (queryParam) {
      dispatch(setQuery(queryParam));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    const isPageRefresh =
      window.performance.getEntriesByType("navigation")[0]?.type === "reload";
    const isFromDataCatalog = searchParams.get("fromDataCatalog");

    // Only fetch if we have a query and either:
    // 1. It's a page refresh OR (NEED TO REIMPLEMENT THIS)
    // 2. We're not coming from data catalog
    // if (query && (isPageRefresh || !isFromDataCatalog)) {
    if (query && !isFromDataCatalog) {
      dispatch(fetchResults(query));
    }

    return () => {
      dispatch(clearError());
    };
  }, [query, dispatch, location.state]);

  const hasValidMapLayers = React.useMemo(() => {
    const uniqueLevels = [
      ...new Set(
        results
          ?.map((r) => r.level)
          .filter(
            (level) => level !== "atlas_school_district" && level !== "country"
          ) || []
      ),
    ];
    if (uniqueLevels.length > 0) {
      return true;
    }
    return false;
  }, [results]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center bg-style-bg gap-4">
        <l-newtons-cradle
          size="120"
          speed="1.4"
          color="black"
        ></l-newtons-cradle>
        {parsingStatus.stage && (
          <div className="text-center">
            <p className="text-lg font-semibold capitalize">
              Parsing in progress
            </p>
            <p className="text-gray-600">{parsingStatus.message}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-style-bg">
      <div className="w-[600px] h-[64px] max-w-xl mt-8">
        <SearchBar useCarousel={false}></SearchBar>
      </div>
      {error ? (
        <div className="mt-8 bg-white p-6 rounded-lg shadow-lg max-w-md">
          <p className="text-gray-700">{error}</p>
        </div>
      ) : results.length > 0 ? (
        <>
          <div
            className={`${view === "map" ? "w-[90vw]" : "min-w-[60vw] max-w-[80vw]"}`}
          >
            <div className="flex justify-between items-center">
              {parameters && <TableParameters parameters={parameters} />}
              {parameters?.search !== "simple" && hasValidMapLayers && (
                <div className="flex justify-end items-center">
                  <div className="flex items-center gap-2 px-4 py-2 rounded-lg">
                    <span className="font-inter font-light tracking-tight text-lg">
                      View:
                    </span>
                    <div className="flex gap-1 bg-style-surface rounded-md p-1">
                      <button
                        onClick={() => handleViewChange("table")}
                        className={`p-1.5 rounded-md transition-colors duration-200 ${
                          view === "table"
                            ? "bg-white text-morange-700 shadow-lg"
                            : "text-slate-600 hover:text-slate-900"
                        }`}
                        title="Table view"
                      >
                        <TableCellsIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => handleViewChange("map")}
                        className={`p-1.5 rounded-md transition-colors duration-200 ${
                          view === "map"
                            ? "bg-white text-morange-700 shadow-lg"
                            : "text-slate-600 hover:text-slate-900"
                        }`}
                        title="Map view"
                      >
                        <MapIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {parameters?.search === "simple" ||
            view === "table" ||
            !hasValidMapLayers ? (
              <Table
                data={results}
                showExportButton={true}
                columnConfig={[
                  {
                    key: "name",
                    type: "link",
                    hrefSource: "geo_query",
                    hrefModifier: (geo_query) =>
                      `/district-profile?${geo_query}`,
                    subtitleCol: "level",
                  },
                ]}
              />
            ) : (
              <ChoroplethMap />
            )}
          </div>
        </>
      ) : (
        <p className="text-gray-500 mt-4">No results found for "{query}"</p>
      )}
    </div>
  );
};

export default SearchResults;
