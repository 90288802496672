import React, { useState, useCallback } from "react";
import { Card } from "../layouts/Card";
import { ChartContainer } from "../charts/ChartContainer";
import useFetch from "../../hooks/fetchData";
import { SupportOpposeChart } from "../charts/SupportOpposeChart";

const TrueViewsCard = ({
  title,
  subtitle,
  footnote = null,
  endpoint,
  comparisonEndpoint,
  endYear = "2024",
  colspan = 1,
  width = null,
  height = 300,
  outline = false,
  className = "",
  chartProps = {},
  id = null,
  children = null,
}) => {
  // Split the endpoint into base and query params
  const getYearEndpoint = (baseEndpoint, year) => {
    const [base, params] = baseEndpoint.split("?");
    const searchParams = new URLSearchParams(params);
    searchParams.delete("year");
    searchParams.append("year", year);
    return `${base}?${searchParams.toString()}`;
  };

  // Only fetch end year data
  const {
    data: endYearData,
    loading: endYearLoading,
    error: endYearError,
  } = useFetch(endpoint ? getYearEndpoint(endpoint, endYear) : null, false);

  // Only fetch end year comparison data if endpoint provided
  const {
    data: comparisonEndYearData,
    loading: comparisonEndYearLoading,
    error: comparisonEndYearError,
  } = useFetch(
    comparisonEndpoint ? getYearEndpoint(comparisonEndpoint, endYear) : null,
    false
  );

  const [showControls, setShowControls] = useState(false);

  const handleMouseEnter = useCallback(() => setShowControls(true), []);
  const handleMouseLeave = useCallback(() => setShowControls(false), []);

  const isLoading = endYearLoading || comparisonEndYearLoading;

  // Format data helper function
  const formatDataForTrueViews = (data) => {
    if (!data || !Array.isArray(data)) return [];
    return data;
  };

  const trueViewsData = endYearData
    ? formatDataForTrueViews(endYearData)
    : null;

  return (
    <Card
      title={title}
      subtitle={subtitle}
      footnote={footnote}
      colspan={colspan}
      outline={outline}
      className={className}
      id={id}
    >
      {children}
      <ChartContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data={trueViewsData}
      >
        <SupportOpposeChart
          height={70}
          data={endYearData?.length > 0 ? endYearData[0].value * 100 : null}
          comparisonData={
            comparisonEndYearData?.length > 0
              ? comparisonEndYearData[0].value * 100
              : null
          }
          loading={isLoading}
        />
      </ChartContainer>
    </Card>
  );
};

export default TrueViewsCard;
