export const filterFeatures = (features, searchTerm) => {
  if (!searchTerm) return features;
  const searchTermLower = searchTerm.toLowerCase();

  const filtered = {};
  Object.entries(features).forEach(([category, features]) => {
    const matchingFeatures = features.filter((feature) => {
      // Check feature name and description
      if (
        feature.display.toLowerCase().includes(searchTermLower) ||
        (feature.description?.toLowerCase() || "").includes(searchTermLower)
      ) {
        return true;
      }

      // Check values if they exist
      if (feature.values) {
        return Object.values(feature.display_values).some((value) =>
          value.toLowerCase().includes(searchTermLower)
        );
      }

      return false;
    });

    if (matchingFeatures.length > 0) {
      filtered[category] = matchingFeatures;
    }
  });
  return filtered;
};
