import React from "react";
import { Tooltip } from "../../text/Tooltip";

export const FeatureButton = ({ feature, description, onClick, selected }) => {
  const button = (
    <button
      onClick={onClick}
      className={`flex flex-col items-center justify-center w-full h-24 font-inter text-style-text-dark shadow-md text-sm rounded-lg border-none hover:bg-style-bg relative
        ${selected ? "bg-style-bg" : "bg-style-surface"}`}
    >
      {selected && (
        <div className="absolute top-2 right-2">
          <svg
            className="w-5 h-5 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      )}
      <span className="mt-2">{feature}</span>
    </button>
  );

  if (!description?.trim()) {
    return button;
  }

  return <Tooltip content={description}>{button}</Tooltip>;
};
