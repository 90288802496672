import React, { useState } from "react";
import { DataCatalogModal } from "../dataCatalog/DataCatalog";

const TableParameters = ({ parameters }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDataCatalog, setShowDataCatalog] = useState(false);
  const [activeTab, setActiveTab] = useState("metrics");

  const renderFilters = (filters) => {
    if (!filters?.filters || filters.filters.length === 0) {
      return (
        <span
          className="text-morange-700 decoration-black cursor-pointer hover:text-morange-800"
          onClick={() => {
            setActiveTab("filters");
            setShowDataCatalog(true);
          }}
        >
          all voters
        </span>
      );
    }

    return filters.filters.map((filter, index) => {
      return (
        <React.Fragment key={filter.key}>
          {index > 0 && " AND "}
          {filter.values.length > 1 && "("}
          {filter.values.map((value, valueIndex) => {
            return (
              <React.Fragment key={`${filter.key}_${value}`}>
                {valueIndex > 0 && " OR "}
                <span
                  className="text-morange-700 group inline-flex items-center cursor-pointer hover:text-morange-800"
                  onClick={() => {
                    setActiveTab("filters");
                    setShowDataCatalog(true);
                  }}
                >
                  <span className="decoration-black">
                    {`${filter.display} ${filter.display_values[valueIndex]}`}
                  </span>
                </span>
              </React.Fragment>
            );
          })}
          {filter.values.length > 1 && ")"}
        </React.Fragment>
      );
    });
  };

  const formatMetricDisplay = (metric) => {
    if (metric.type === "continuous" || metric.type === "estimate") {
      return metric.display;
    }

    if (!metric.value || metric.value[0] === "all") {
      return metric.display;
    }

    // For categorical metrics with one value
    if (metric.value.length === 1) {
      return `${metric.display} ${metric.display_values[0]}`;
    }

    // For categorical metrics with multiple values
    return `${metric.display} (${metric.display_values.join(", ")})`;
  };

  const renderMetrics = (metrics) => {
    if (!metrics?.metrics || metrics.metrics.length === 0) {
      return (
        <span
          className="text-morange-700 decoration-black cursor-pointer hover:text-morange-800"
          onClick={() => {
            setActiveTab("metrics");
            setShowDataCatalog(true);
          }}
        >
          Registered Voters
        </span>
      );
    }

    // Deduplicate metrics based on metric name only
    const uniqueMetrics = metrics.metrics.reduce((acc, metric) => {
      const key = metric.metric;
      if (!acc[key]) {
        acc[key] = { ...metric };
      } else {
        // Combine values into a unique set
        const allValues = [...(acc[key].value || []), ...(metric.value || [])];
        acc[key].value = Array.from(new Set(allValues));

        // Track aggregations
        acc[key].aggregations = acc[key].aggregations || [acc[key].aggregation];
        if (!acc[key].aggregations.includes(metric.aggregation)) {
          acc[key].aggregations.push(metric.aggregation);
        }
      }
      return acc;
    }, {});

    return Object.values(uniqueMetrics).map((metric, index, array) => (
      <React.Fragment key={metric.metric}>
        <span
          className="text-morange-700 group inline-flex items-center cursor-pointer hover:text-morange-800"
          onClick={() => {
            setActiveTab("metrics");
            setShowDataCatalog(true);
          }}
        >
          <span className="decoration-black">
            {formatMetricDisplay(metric)}
          </span>
        </span>
        {index < array.length - 1 && ", "}
      </React.Fragment>
    ));
  };

  const renderPlaces = (places) => {
    if (!places || places.length === 0) {
      return (
        <span
          className="text-morange-700 decoration-black cursor-pointer hover:text-morange-800"
          onClick={() => {
            setActiveTab("places");
            setShowDataCatalog(true);
          }}
        >
          Select places
        </span>
      );
    }

    const placeNames = places.map(
      (place) => place.geography_pair || place.name
    );
    const PLACE_DISPLAY_THRESHOLD = 3;

    if (placeNames.length > PLACE_DISPLAY_THRESHOLD) {
      return (
        <span
          className="text-morange-700 cursor-pointer hover:text-morange-800"
          onClick={() => {
            setActiveTab("places");
            setShowDataCatalog(true);
          }}
        >
          <span className="decoration-black">
            {placeNames.slice(0, PLACE_DISPLAY_THRESHOLD).join(" • ")}
            <button
              className="ml-1 font-normal hover:text-morange-800 underline"
              onClick={(e) => {
                e.stopPropagation(); // Prevent opening DataCatalog when clicking "show more"
                setIsExpanded(!isExpanded);
              }}
            >
              {isExpanded
                ? "show less"
                : `and ${placeNames.length - PLACE_DISPLAY_THRESHOLD} more`}
            </button>
          </span>
          <div
            className="mt-2 ml-4 text-morange-700 decoration-black text-sm leading-relaxed"
            style={{ display: isExpanded ? "block" : "none" }}
          >
            {placeNames.join(" • ")}
          </div>
        </span>
      );
    }

    return (
      <span
        className="text-morange-700 cursor-pointer hover:text-morange-800"
        onClick={() => {
          setActiveTab("places");
          setShowDataCatalog(true);
        }}
      >
        <span className="decoration-black">{placeNames.join(" • ")}</span>
      </span>
    );
  };

  return (
    <>
      <div className="w-full mt-8 flex flex-col container mx-auto items-start space-y-4">
        <div className="text-lg max-w-prose tracking-tight font-light">
          You're viewing {renderMetrics(parameters.metrics)} for{" "}
          {renderFilters(parameters.filters)} in{" "}
          {renderPlaces(parameters.places)}{" "}
        </div>
      </div>

      <DataCatalogModal
        isOpen={showDataCatalog}
        onClose={() => setShowDataCatalog(false)}
        firstActiveTab={activeTab}
      />
    </>
  );
};

export default TableParameters;
