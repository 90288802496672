import React, { useState } from "react";
import { withParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import { Tooltip, defaultStyles } from "@visx/tooltip";
import { hatch } from "ldrs";
import { useSelector } from "react-redux";
import { OPPOSE_COLOR, SUPPORT_COLOR } from "../../utility/formatText";
import { colors } from "../../utility/colors";

// Register loader component
hatch.register();

// Define colors directly in the component
const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: "white",
  color: colors.mcharcoal[700],
  padding: "8px 12px",
  border: `1px solid ${colors.mcharcoal[700]}`,
  borderRadius: "4px",
};

const ARROW_HEAD_SIZE = 15;

// Function to color-code support/oppose text
const colorCodeText = (text) => {
  if (!text) return null;

  const cleanedText = text.replace(/,\s*/g, " ");

  const parts = cleanedText.split(/(support|oppose)/i);
  return (
    <span>
      {parts.map((part, i) => {
        const lowerPart = part.toLowerCase();
        if (lowerPart === "support") {
          return (
            <span key={i} style={{ color: SUPPORT_COLOR }}>
              {part} the zero 50 and 100%
            </span>
          );
        } else if (lowerPart === "oppose") {
          return (
            <span key={i} style={{ color: OPPOSE_COLOR }}>
              {part}
            </span>
          );
        }
        return part;
      })}
    </span>
  );
};

const BaseWindChart = ({
  data = [],
  comparisonData = null,
  height = null,
  width = null,
  parentHeight = null,
  parentWidth = null,
  verticalMargin = 80,
  horizontalMargin = 60,
  loading = false,
  startYear = "2020",
  endYear = "2024",
}) => {
  const districtInfo = useSelector((state) => state.districtPage.districtInfo);
  const comparisonInfo = useSelector(
    (state) => state.districtPage.comparisonInfo
  );
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipLeft, setTooltipLeft] = useState(0);
  const [tooltipTop, setTooltipTop] = useState(0);

  // Determine if we have comparison data
  const hasComparison = comparisonData !== null && comparisonData.length === 2;

  // Use parent dimensions if available
  const actualWidth = width || parentWidth || 800;
  const baseHeight = height || parentHeight || 300;

  // Keep the reduced height when no comparison
  const actualHeight = hasComparison ? baseHeight : baseHeight * 0.6;

  // Set the margins
  const xMax = actualWidth - horizontalMargin * 2;
  const yMax = actualHeight - verticalMargin * 2;

  // Calculate baseline position - true middle for comparison, bottom for single
  const baselineY = hasComparison ? yMax / 2 : yMax;

  // Keep arrow positions consistent
  const mainArrowY = yMax / 4; // Top quarter
  const comparisonArrowY = (yMax * 3) / 4; // Bottom quarter

  // Add padding for labels above the arrow
  const labelPadding = 40; // Adjust this value based on your label height

  // Create scale for values (0-100%)
  const valueScale = scaleLinear({
    domain: [0, 100],
    range: [0, xMax],
    nice: true,
  });

  if (actualWidth < 10 || actualHeight < 10) return null;

  // Function to calculate arrow properties
  const calculateArrowProperties = (startYear, endYear) => {
    const startValue = startYear.value;
    const endValue = endYear.value;
    const change = endValue - startValue;
    const color = endValue >= 0.5 ? SUPPORT_COLOR : OPPOSE_COLOR;
    const direction = endValue < startValue ? "left" : "right";

    return {
      x1: valueScale(startValue * 100),
      x2: valueScale(endValue * 100),
      color,
      change,
      direction,
    };
  };

  // Function to draw arrow head
  const drawArrowHead = (x, y, direction, color) => {
    return (
      <path
        d={`M ${x} ${y}
            l ${direction === "right" ? -ARROW_HEAD_SIZE : ARROW_HEAD_SIZE} ${-ARROW_HEAD_SIZE / 2}
            l 0 ${ARROW_HEAD_SIZE}
            Z`}
        fill={color}
      />
    );
  };

  // Function to draw point label
  const drawPointLabel = (
    x,
    y,
    data,
    isAbove = true,
    isStart = true,
    otherX = null
  ) => {
    const baseYOffset = isAbove ? -35 : 25; // Reduced vertical offset
    const lineSpacing = 16; // Slightly reduced line spacing
    const minDistance = 50; // Reduced minimum distance
    const maxOffset = 30; // Maximum label offset
    const value = data.value * 100;

    // If otherX is provided and points are too close, adjust x position
    let adjustedX = x;
    let textAnchor = "middle";

    if (otherX !== null) {
      const distance = Math.abs(x - otherX);
      if (distance < minDistance) {
        // Calculate proportional offset based on how close the points are
        const offsetAmount = Math.min(maxOffset, (minDistance - distance) / 2);
        adjustedX = x + (isStart ? -offsetAmount : offsetAmount);
        textAnchor = isStart ? "end" : "start";
      }
    }

    return (
      <g>
        <Text
          x={adjustedX}
          y={y + baseYOffset}
          textAnchor={textAnchor}
          fill={colors.mcharcoal[700]}
          fontSize={12}
          fontWeight="bold"
          fontFamily="Inter, sans-serif"
        >
          {isStart ? startYear : endYear}
        </Text>
        <Text
          x={adjustedX}
          y={y + baseYOffset + lineSpacing}
          textAnchor={textAnchor}
          fill={colors.mcharcoal[700]}
          fontSize={12}
          fontFamily="Inter, sans-serif"
        >
          {`${value.toFixed(1)}%`}
        </Text>
      </g>
    );
  };

  const handleMouseOver = (event, yearData, arrowProps) => {
    const bounds = event.currentTarget.getBoundingClientRect();
    const svgBounds =
      event.currentTarget.ownerSVGElement.getBoundingClientRect();

    setTooltipData({
      startValue: yearData[0].value * 100,
      startError: yearData[0].standard_error * 100,
      endValue: yearData[1].value * 100,
      endError: yearData[1].standard_error * 100,
      change: arrowProps.change * 100,
      isComparison: yearData === comparisonData,
    });
    setTooltipLeft(bounds.left - svgBounds.left + bounds.width / 2);
    setTooltipTop(bounds.top - svgBounds.top - 40);
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  return (
    <>
      {loading ? (
        <div className="relative w-full" style={{ height: actualHeight + 40 }}>
          <div className="w-full h-full flex items-center justify-center">
            <l-hatch size="32" color="#334155"></l-hatch>
          </div>
        </div>
      ) : (
        <div className="relative w-full">
          <svg
            width="100%"
            height={actualHeight + 40}
            preserveAspectRatio="xMidYMid meet"
            viewBox={`0 0 ${actualWidth} ${actualHeight + 40}`}
            className="w-full"
          >
            <Group top={verticalMargin} left={horizontalMargin}>
              {/* Background fills - adjusted for baseline position */}
              <rect
                x={0}
                y={hasComparison ? -labelPadding : -labelPadding}
                width={valueScale(50)}
                height={
                  hasComparison ? yMax + labelPadding * 2 : yMax + labelPadding
                }
                fill={colors.chartColors.opposeSoft}
              />
              <rect
                x={valueScale(50)}
                y={hasComparison ? -labelPadding : -labelPadding}
                width={xMax - valueScale(50)}
                height={
                  hasComparison ? yMax + labelPadding * 2 : yMax + labelPadding
                }
                fill={colors.chartColors.supportSoft}
              />

              {/* Draw baseline */}
              <Line
                from={{ x: 0, y: baselineY }}
                to={{ x: xMax, y: baselineY }}
                stroke={colors.mcharcoal["700"]}
                strokeWidth={2}
              />

              {/* Draw 50% marker */}
              <Line
                from={{ x: valueScale(50), y: -labelPadding }}
                to={{
                  x: valueScale(50),
                  y: hasComparison ? yMax + labelPadding : yMax,
                }}
                stroke={colors.mcharcoal["700"]}
                strokeWidth={2}
                strokeDasharray="10, 5"
              />

              {/* Draw main data arrow */}
              {data.length === 2 &&
                (() => {
                  const arrowProps = calculateArrowProperties(data[0], data[1]);
                  const [leftData, leftYear, rightData, rightYear] =
                    data[0].value < data[1].value
                      ? [data[0], startYear, data[1], endYear]
                      : [data[1], endYear, data[0], startYear];
                  const leftX = valueScale(leftData.value * 100);
                  const rightX = valueScale(rightData.value * 100);

                  // Calculate label positions
                  const minDistance = 50;
                  const maxOffset = 30;
                  const distance = Math.abs(leftX - rightX);
                  let leftAdjustedX = leftX;
                  let rightAdjustedX = rightX;
                  let leftAnchor = "middle";
                  let rightAnchor = "middle";

                  if (distance < minDistance) {
                    const offsetAmount = Math.min(
                      maxOffset,
                      (minDistance - distance) / 2
                    );
                    leftAdjustedX = leftX - offsetAmount;
                    rightAdjustedX = rightX + offsetAmount;
                    leftAnchor = "end";
                    rightAnchor = "start";
                  }

                  return (
                    <g
                      onMouseOver={(e) => handleMouseOver(e, data, arrowProps)}
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: "pointer" }}
                    >
                      <Line
                        x1={arrowProps.x1}
                        y1={mainArrowY}
                        x2={arrowProps.x2}
                        y2={mainArrowY}
                        stroke={arrowProps.color}
                        strokeWidth={3.5}
                      />
                      {drawArrowHead(
                        arrowProps.x2,
                        mainArrowY,
                        arrowProps.direction,
                        arrowProps.color
                      )}
                      {/* Left point labels */}
                      <Text
                        x={leftAdjustedX}
                        y={mainArrowY - 35}
                        textAnchor={leftAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontWeight="bold"
                        fontFamily="Inter, sans-serif"
                      >
                        {leftYear}
                      </Text>
                      <Text
                        x={leftAdjustedX}
                        y={mainArrowY - 19}
                        textAnchor={leftAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontFamily="Inter, sans-serif"
                      >
                        {`${(leftData.value * 100).toFixed(1)}%`}
                      </Text>
                      {/* Right point labels */}
                      <Text
                        x={rightAdjustedX}
                        y={mainArrowY - 35}
                        textAnchor={rightAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontWeight="bold"
                        fontFamily="Inter, sans-serif"
                      >
                        {rightYear}
                      </Text>
                      <Text
                        x={rightAdjustedX}
                        y={mainArrowY - 19}
                        textAnchor={rightAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontFamily="Inter, sans-serif"
                      >
                        {`${(rightData.value * 100).toFixed(1)}%`}
                      </Text>
                    </g>
                  );
                })()}

              {/* Draw comparison data arrow only if comparison data exists */}
              {hasComparison &&
                (() => {
                  const arrowProps = calculateArrowProperties(
                    comparisonData[0],
                    comparisonData[1]
                  );
                  const [leftData, leftYear, rightData, rightYear] =
                    comparisonData[0].value < comparisonData[1].value
                      ? [
                          comparisonData[0],
                          startYear,
                          comparisonData[1],
                          endYear,
                        ]
                      : [
                          comparisonData[1],
                          endYear,
                          comparisonData[0],
                          startYear,
                        ];
                  const leftX = valueScale(leftData.value * 100);
                  const rightX = valueScale(rightData.value * 100);

                  // Calculate label positions
                  const minDistance = 50;
                  const maxOffset = 30;
                  const distance = Math.abs(leftX - rightX);
                  let leftAdjustedX = leftX;
                  let rightAdjustedX = rightX;
                  let leftAnchor = "middle";
                  let rightAnchor = "middle";

                  if (distance < minDistance) {
                    const offsetAmount = Math.min(
                      maxOffset,
                      (minDistance - distance) / 2
                    );
                    leftAdjustedX = leftX - offsetAmount;
                    rightAdjustedX = rightX + offsetAmount;
                    leftAnchor = "end";
                    rightAnchor = "start";
                  }

                  return (
                    <g
                      onMouseOver={(e) =>
                        handleMouseOver(e, comparisonData, arrowProps)
                      }
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: "pointer" }}
                    >
                      <Line
                        x1={arrowProps.x1}
                        y1={comparisonArrowY}
                        x2={arrowProps.x2}
                        y2={comparisonArrowY}
                        stroke={arrowProps.color}
                        strokeWidth={3}
                      />
                      {drawArrowHead(
                        arrowProps.x2,
                        comparisonArrowY,
                        arrowProps.direction,
                        arrowProps.color
                      )}
                      {/* Left point labels */}
                      <Text
                        x={leftAdjustedX}
                        y={comparisonArrowY + 25}
                        textAnchor={leftAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontWeight="bold"
                        fontFamily="Inter, sans-serif"
                      >
                        {leftYear}
                      </Text>
                      <Text
                        x={leftAdjustedX}
                        y={comparisonArrowY + 41}
                        textAnchor={leftAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontFamily="Inter, sans-serif"
                      >
                        {`${(leftData.value * 100).toFixed(1)}%`}
                      </Text>
                      {/* Right point labels */}
                      <Text
                        x={rightAdjustedX}
                        y={comparisonArrowY + 25}
                        textAnchor={rightAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontWeight="bold"
                        fontFamily="Inter, sans-serif"
                      >
                        {rightYear}
                      </Text>
                      <Text
                        x={rightAdjustedX}
                        y={comparisonArrowY + 41}
                        textAnchor={rightAnchor}
                        fill={colors.mcharcoal[700]}
                        fontSize={12}
                        fontFamily="Inter, sans-serif"
                      >
                        {`${(rightData.value * 100).toFixed(1)}%`}
                      </Text>
                    </g>
                  );
                })()}

              {/* X-axis labels */}
              <Text
                x={0}
                y={yMax + (hasComparison ? labelPadding + 20 : 20)}
                textAnchor="start"
                fill={OPPOSE_COLOR}
                fontSize={12}
                fontFamily="Inter, sans-serif"
              >
                0%
              </Text>
              <Text
                x={valueScale(50)}
                y={yMax + (hasComparison ? labelPadding + 20 : 20)}
                textAnchor="middle"
                fill={colors.mcharcoal[700]}
                fontSize={12}
                fontFamily="Inter, sans-serif"
              >
                50%
              </Text>
              <Text
                x={xMax}
                y={yMax + (hasComparison ? labelPadding + 20 : 20)}
                textAnchor="end"
                fill={SUPPORT_COLOR}
                fontSize={12}
                fontFamily="Inter, sans-serif"
              >
                100%
              </Text>
            </Group>
          </svg>
          {tooltipData && (
            <Tooltip style={tooltipStyles} top={tooltipTop} left={tooltipLeft}>
              <div className="text-sm">
                <div className="font-medium mb-1">
                  <b>
                    {tooltipData.isComparison
                      ? comparisonInfo?.name || "Comparison District"
                      : districtInfo?.name || "Unknown District"}
                  </b>
                </div>
                <div>
                  <b>{endYear}:</b> {tooltipData.endValue.toFixed(1)}% ±
                  {tooltipData.endError.toFixed(1)}%
                </div>
                <div>
                  <b>{startYear}:</b> {tooltipData.startValue.toFixed(1)}% ±
                  {tooltipData.startError.toFixed(1)}%
                </div>
                <div>
                  <b>Change:</b>{" "}
                  <span
                    style={{
                      color:
                        tooltipData.change >= 0
                          ? colors.mlime.default
                          : colors.mrose.default,
                    }}
                  >
                    {tooltipData.change > 0 ? "+" : ""}
                    {tooltipData.change.toFixed(1)}%
                  </span>
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};

const WindChart = withParentSize(BaseWindChart);

export { WindChart };
