import React from "react";

export const Tooltip = ({ children, content }) => {
  return (
    <div className="group relative w-full">
      {children}
      <div
        className="opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-200 
                    absolute z-50 bottom-full left-1/2 transform -translate-x-1/2 mb-2
                    px-3 py-2 text-sm text-white bg-gray-900 rounded-lg shadow-lg
                    w-48 text-center pointer-events-none"
      >
        {content}
        <div
          className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-2
                      border-4 border-transparent border-t-gray-900"
        />
      </div>
    </div>
  );
};
