import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utility/axios";
import oktaAuth from "../auth/oktaAuth";

export const fetchResults = createAsyncThunk(
  "search/fetchResults",
  async (query, { dispatch, rejectWithValue }) => {
    try {
      const encodedQuery = encodeURIComponent(query);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}api/search/parameters?query=${encodedQuery}`,
        {
          headers: {
            Accept: "text/event-stream",
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${await oktaAuth.getAccessToken()}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue({
          message:
            errorData.message || "An error occurred while fetching results",
        });
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let result = null;
      let completeData = "";
      let collectingComplete = false;
      let chunkCount = 0;

      while (true) {
        const { done, value } = await reader.read();
        chunkCount++;

        if (done) {
          return result;
        }

        const chunk = decoder.decode(value, { stream: true });

        if (chunk.includes("event: status")) {
          const match = chunk.match(/data: (.+?)\n\n/);
          if (match) {
            const data = JSON.parse(match[1]);
            dispatch(
              setParsingStatus({
                stage: data.stage,
                message: data.message,
              })
            );
          }
        }

        if (chunk.includes("event: complete")) {
          collectingComplete = true;
          completeData = chunk;

          // Match data that comes after "event: complete"
          const match = chunk.match(/event: complete\ndata: (.+?)(\n\n|$)/);
          if (match) {
            try {
              const functionParams = JSON.parse(match[1]);
              // Store the function parameters in the state
              dispatch(setParameters(functionParams));

              try {
                // Execute the search with the function parameters
                const searchResults = await apiClient.post(
                  "/api/search/execute",
                  functionParams
                );
                return searchResults.data;
              } catch (postError) {
                return rejectWithValue({
                  message:
                    postError.response?.data?.message ||
                    "Error executing search request",
                });
              }
            } catch (e) {
              continue;
            }
          }
        } else if (collectingComplete) {
          completeData += chunk;
          try {
            const functionParams = JSON.parse(
              completeData.match(/event: complete\ndata: (.+?)(\n\n|$)/)[1]
            );
            // Store the function parameters in the state
            dispatch(setParameters(functionParams));

            try {
              const searchResults = await apiClient.post(
                "/api/search/execute",
                functionParams
              );
              return searchResults.data;
            } catch (postError) {
              return rejectWithValue({
                message:
                  postError.response?.data?.message ||
                  "Error executing search request",
              });
            }
          } catch (e) {
            continue;
          }
        }
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error fetching search results."
      );
    }
  }
);

const initialState = {
  parameters: {
    metrics: {
      metrics: [],
    },
    filters: {
      filters: [],
      needs_filtering: false,
    },
    places: [],
  },
  results: [],
  loading: false,
  error: null,
  query: "",
  parsingStatus: {
    stage: null,
    message: null,
  },
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setParameters: (state, action) => {
      state.parameters = action.payload;
    },
    resetParameters: (state) => {
      state.parameters = initialState.parameters;
    },
    clearError: (state) => {
      state.error = null;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setParsingStatus: (state, action) => {
      state.parsingStatus = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.parsingStatus = {
          stage: null,
          message: null,
        };
      })
      .addCase(fetchResults.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.parsingStatus = {
          stage: "complete",
          message: null,
        };
        state.results = action.payload;
      })
      .addCase(fetchResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "An unknown error occurred";
        state.parsingStatus = {
          stage: "error",
          message: action.payload?.message || "An unknown error occurred",
        };
        state.results = [];
      });
  },
});

export const {
  setParameters,
  addParam,
  removeParam,
  resetParameters,
  clearError,
  setResults,
  setQuery,
  setParsingStatus,
  setLoading,
} = searchSlice.actions;
export default searchSlice.reducer;
