// frontend/src/hooks/useSelectionManager.js
import { useState, useCallback } from "react";
import { FeatureTypeConfig } from "../components/dataCatalog/config/featureTypes";

export const useSelectionManager = (initialParameters = {}) => {
  const [selections, setSelections] = useState(initialParameters);

  const addSelection = useCallback((item, type) => {
    setSelections((prev) => {
      const newSelections = { ...prev };

      switch (type) {
        case "metrics":
          const metricConfig = FeatureTypeConfig[item.type];
          if (!metricConfig) {
            console.error(
              `No configuration found for metric type: ${item.type}`
            );
            return prev;
          }
          newSelections.metrics = {
            metrics: metricConfig.handleMetricSelection(
              prev.metrics?.metrics || [],
              item
            ),
          };
          break;
        case "filters":
          const filterConfig = FeatureTypeConfig.filter;
          if (!filterConfig) {
            console.error("No configuration found for filter type");
            return prev;
          }
          newSelections.filters = {
            filters: filterConfig.handleFilterSelection(
              prev.filters?.filters || [],
              item
            ),
            needs_filtering: true,
          };
          break;
        case "places":
          const placeConfig = FeatureTypeConfig.places;
          if (!placeConfig) {
            console.error("No configuration found for places type");
            return prev;
          }
          newSelections.places = placeConfig.handlePlaceSelection(
            prev.places || [],
            item
          );
          break;
        default:
          console.error(`Unknown selection type: ${type}`);
          return prev;
      }

      return newSelections;
    });
  }, []);

  const removeSelection = useCallback((item, type) => {
    setSelections((prev) => {
      const newSelections = { ...prev };

      switch (type) {
        case "metrics":
          const metricConfig = FeatureTypeConfig[item.type];
          if (!metricConfig) {
            console.error(
              `No configuration found for metric type: ${item.type}`
            );
            return prev;
          }
          newSelections.metrics = {
            metrics: metricConfig.handleMetricRemoval(
              prev.metrics?.metrics || [],
              item
            ),
          };
          break;
        case "filters":
          const filterConfig = FeatureTypeConfig.filter;
          if (!filterConfig) {
            console.error("No configuration found for filter type");
            return prev;
          }
          newSelections.filters = {
            filters: filterConfig.handleFilterRemoval(
              prev.filters?.filters || [],
              item
            ),
            needs_filtering: prev.filters?.filters.length > 1,
          };
          break;
        case "places":
          const placeConfig = FeatureTypeConfig.places;
          if (!placeConfig) {
            console.error("No configuration found for places type");
            return prev;
          }
          newSelections.places = placeConfig.handlePlaceRemoval(
            prev.places || [],
            item
          );
          break;
        default:
          console.error(`Unknown selection type: ${type}`);
          return prev;
      }

      return newSelections;
    });
  }, []);

  const isSelected = useCallback(
    (item, type) => {
      switch (type) {
        case "metrics":
          const metricConfig = FeatureTypeConfig[item.type];
          if (!metricConfig) {
            console.error(
              `No configuration found for metric type: ${item.type}`
            );
            return false;
          }
          return metricConfig.isSelected(selections[type], item);
        case "filters":
          const filterConfig = FeatureTypeConfig.filter;
          if (!filterConfig) {
            console.error("No configuration found for filter type");
            return false;
          }
          return filterConfig.isSelected(selections[type], item);
        case "places":
          const placeConfig = FeatureTypeConfig.places;
          if (!placeConfig) {
            console.error("No configuration found for places type");
            return false;
          }
          return placeConfig.isSelected(selections[type], item);
        default:
          console.error(`Unknown selection type: ${type}`);
          return false;
      }
    },
    [selections]
  );

  return {
    selections,
    addSelection,
    removeSelection,
    isSelected,
  };
};
