import {
  UserGroupIcon,
  CalendarIcon,
  IdentificationIcon,
  ScaleIcon,
  MapIcon,
  HeartIcon,
  CurrencyDollarIcon,
  UsersIcon,
  AcademicCapIcon,
  ClipboardDocumentCheckIcon,
  ChartBarIcon,
  HandThumbUpIcon,
  LightBulbIcon,
} from "@heroicons/react/24/solid";

export const searchSuggestions = [
  "partisanship in North Carolina by county",
  "abortion support in FL congressional districts",
  "New York",
  "state upper houses in TX",
  "Trump support in Los Angeles County, CA",
  "turnout in WY counties",
  "Shelby County",
];

export const tabColors = {
  metrics: "style-primary",
  filters: "style-secondary",
  places: "style-green",
};

export const icons = {
  ethnicity: <UserGroupIcon />,
  age: <CalendarIcon />,
  gender: <IdentificationIcon />,
  early_vote: <CalendarIcon />,
  partisanship: <ScaleIcon />,
  urbanicity: <MapIcon />,
  marital_status: <HeartIcon />,
  income: <CurrencyDollarIcon />,
  presence_of_children: <UsersIcon />,
  education: <AcademicCapIcon />,
  vote_history: <ClipboardDocumentCheckIcon />,
  turnout_scores: <ChartBarIcon />,
  scores: <HandThumbUpIcon />,
  TrueViews: <LightBulbIcon />,
};
