import React from "react";
import { Section } from "../../../components/text/Section";

export const ReleaseNotes = () => (
  <div className="prose prose-slate max-w-none font-inter">
    <Section>
      <h2 className="text-3xl font-semibold text-slate-900 mb-4">
        Release Notes
      </h2>
      <p className="text-lg text-slate-600 leading-relaxed">
        This page keeps track of the latest features and improvements in
        Explorer.
      </p>
    </Section>
    <Section title="February 2025">
      <ul className="list-disc pl-6 space-y-2">
        <li>Improved search accuracy for more relevant results</li>
        <li>
          Enhanced Data Catalog UI, including tooltips for feature descriptions
        </li>
        <li>Updated map colors for better readability</li>
        <li>Added scroll functionality to search results table</li>
      </ul>
    </Section>
    <Section title="January 2025, Second Release">
      <ul className="list-disc pl-6 space-y-2">
        <li>Database improvements to increase performance and reliability</li>
      </ul>
    </Section>
    <Section title="January 2025, First Release">
      <ul className="list-disc pl-6 space-y-2">
        <li>Add Help Center</li>
        <li>UI Improvements to Data Catalog</li>
        <li>Atlas School Sub-districts addition</li>
        <li>Improve performance of geography search</li>
      </ul>
    </Section>
    <Section title="November 2024" isLast={true}>
      <ul className="list-disc pl-6 space-y-2">
        <li>View data in map form for geography-level searches</li>
        <li>Search for Census Designated Places (towns, cities, etc.)</li>
        <li>View turnout across general, primary, and municipal elections</li>
      </ul>
    </Section>
  </div>
);
