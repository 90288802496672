import { useState } from "react";

export const useFeatureNavigation = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [isDirectNavigation, setIsDirectNavigation] = useState(false);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedFeature(null);
    setIsDirectNavigation(false);
  };

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
  };

  const handleDirectCategoryAndFeature = (category, feature) => {
    setSelectedCategory(category);
    setSelectedFeature(feature);
    setIsDirectNavigation(true);
  };

  const handleBack = () => {
    if (selectedFeature && !isDirectNavigation) {
      setSelectedFeature(null);
    } else {
      setSelectedCategory(null);
      setSelectedFeature(null);
      setIsDirectNavigation(false);
    }
  };

  return {
    selectedCategory,
    selectedFeature,
    handleCategoryClick,
    handleFeatureClick,
    handleBack,
    handleDirectCategoryAndFeature,
    isDirectNavigation,
  };
};
