// frontend/src/components/dataCatalog/tabs/MetricsTab.js
import React, { useState } from "react";
import { newtonsCradle } from "ldrs";
import { CategoryButton } from "../components/CategoryButton";
import { FeatureButton } from "../components/FeatureButton";
import { BackButton } from "../components/BackButton";
import { useFeatureNavigation } from "../../../utility/dataCatalog/useFeatureNavigation";
import { useFetchFeatures } from "../../../utility/useFetchFeatures";
import { filterFeatures } from "../../../utility/dataCatalog/filterFeatures";
import { CatalogSearchBar } from "../components/CatalogSearchBar";
import { FeatureTypeConfig } from "../config/featureTypes";

newtonsCradle.register();

const MetricsTab = ({ onMetricSelect, isItemSelected }) => {
  const {
    selectedCategory,
    selectedFeature,
    handleCategoryClick,
    handleFeatureClick,
    handleBack,
    handleDirectCategoryAndFeature,
    isDirectNavigation,
  } = useFeatureNavigation();

  const [selectedValue, setSelectedValue] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const { categorizedFeatures, isLoading } = useFetchFeatures(
    "/api/config/metrics"
  );
  const filteredFeatures = filterFeatures(categorizedFeatures, searchTerm);

  const handleMetricSelection = (metricItem) => {
    onMetricSelect(metricItem);
  };

  const handleValueClick = (key, value) => {
    setSelectedValue({ key, value });
  };

  const handleBackWithValue = () => {
    if (selectedValue) {
      setSelectedValue(null);
    } else {
      handleBack();
    }
  };

  const handleCategoryOrFeatureClick = (category, features) => {
    if (features.length === 1 && features[0].name === category) {
      handleDirectCategoryAndFeature(category, features[0]);
    } else {
      handleCategoryClick(category);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <l-newtons-cradle
          size="120"
          speed="1.4"
          color="black"
        ></l-newtons-cradle>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {!selectedFeature && (
        <CatalogSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
      {(selectedCategory || selectedValue) && (
        <BackButton
          onClick={handleBackWithValue}
          backTarget={
            selectedFeature && !isDirectNavigation
              ? selectedCategory
              : "Categories"
          }
        />
      )}

      {!selectedCategory ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {Object.entries(filteredFeatures).map(([category, features]) => (
            <CategoryButton
              key={category}
              category={category}
              onClick={() => handleCategoryOrFeatureClick(category, features)}
            />
          ))}
        </div>
      ) : !selectedFeature ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {filteredFeatures[selectedCategory]?.map((feature) => (
            <FeatureButton
              key={feature.name}
              feature={feature.display || feature.name}
              description={feature.description}
              onClick={() => handleFeatureClick(feature)}
              selected={false}
            />
          ))}
        </div>
      ) : selectedFeature.type === "estimate" ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {selectedFeature.years?.map((year) => {
            const config = FeatureTypeConfig[selectedFeature.type];
            const metricItem = config.createMetricItem(selectedFeature, year);
            const selected = isItemSelected(metricItem, "metrics");
            return (
              <FeatureButton
                key={year}
                feature={year}
                onClick={() => handleMetricSelection(metricItem)}
                selected={selected}
              />
            );
          })}
        </div>
      ) : selectedFeature.type === "continuous" ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {selectedFeature.available_aggregations.map((agg) => {
            const config = FeatureTypeConfig[selectedFeature.type];
            const metricItem = config.createMetricItem(selectedFeature, agg);
            const selected = isItemSelected(metricItem, "metrics");
            return (
              <FeatureButton
                key={agg}
                feature={agg}
                onClick={() => handleMetricSelection(metricItem)}
                selected={selected}
              />
            );
          })}
        </div>
      ) : !selectedValue ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {selectedFeature.values.map((value, idx) => (
            <FeatureButton
              key={value}
              feature={selectedFeature.display_values[idx]}
              description={selectedFeature.description}
              onClick={() =>
                handleValueClick(value, selectedFeature.display_values[idx])
              }
              selected={false}
            />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {selectedFeature.available_aggregations.map((agg) => {
            const config = FeatureTypeConfig[selectedFeature.type];
            const metricItem = config.createMetricItem(
              selectedFeature,
              agg,
              selectedValue
            );
            const selected = isItemSelected(metricItem, "metrics");
            return (
              <FeatureButton
                key={agg}
                feature={agg}
                onClick={() => handleMetricSelection(metricItem)}
                selected={selected}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export { MetricsTab };
