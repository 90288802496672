import React, { useCallback } from "react";
import { formatLargeNumber } from "../../utility/numberFormats";
import { formatTitle } from "../../utility/formatText";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const TableBody = ({ sortedData, visibleColumns, columnMaxValues }) => {
  const getColumnClass = useCallback((column) => {
    const type = column.type || "text";
    switch (type) {
      case "numeric":
      case "percentage":
        return "text-right max-w-[8rem] min-w-[8rem]";
      case "link":
        return "min-w-[12rem]";
      default:
        return "min-w-[8rem]";
    }
  }, []);

  const formatCellContent = useCallback((value, column) => {
    const type = column.type || "text";
    if (type === "numeric" && typeof value === "number") {
      return formatLargeNumber(value);
    } else if (type === "percentage" && typeof value === "number") {
      return `${(value * 100).toFixed(0)}%`;
    }
    return value;
  }, []);

  const renderCell = useCallback(
    (row, column) => {
      const value = row[column.key];
      const formattedValue = formatCellContent(value, column);

      if (column.type === "link") {
        let href = row[column.hrefSource];
        if (column.hrefModifier && typeof column.hrefModifier === "function") {
          href = column.hrefModifier(href, row);
        }
        return (
          <>
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-style-secondary hover:underline flex items-center h-full"
            >
              {formattedValue}
            </a>
            <span className="text-xs text-mcharcoal-500">
              {formatTitle(row[column.subtitleCol])}
            </span>
          </>
        );
      }

      if (
        (column.type === "numeric" || column.type === "percentage") &&
        typeof value === "number"
      ) {
        const maxValue = columnMaxValues[column.key];
        const percentage = (value / maxValue) * 100;
        return (
          <div className="flex items-center justify-end h-full font-inter font-medium text-sm text-mcharcoal-900">
            <span className="w-1/2 text-right mr-3">{formattedValue}</span>
            <div className="w-1/2 h-3 bg-gray-200">
              <div
                className="h-full bg-style-secondary"
                style={{ width: `${percentage}%` }}
              ></div>
            </div>
          </div>
        );
      }

      return <div className="flex items-center h-full">{formattedValue}</div>;
    },
    [columnMaxValues, formatCellContent]
  );

  return (
    <tbody>
      {sortedData.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          className={
            rowIndex !== sortedData.length
              ? "border-t border-mcharcoal-400 hover:bg-morange-100"
              : "hover:bg-morange-100"
          }
        >
          {visibleColumns.map((column) => (
            <td
              key={column.key}
              className={`px-6 py-3 ${getColumnClass(column)}`}
            >
              {renderCell(row, column)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
